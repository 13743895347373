import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

/* common */
import * as Helper from "../common/Helper.js";

/* page */
import LinkChinese from "./LinkChinese";
import LinkKey from "./LinkKey";

/* css */

function Link({ match, history, location }) {
  const pageId = Helper.getPageId(match.url);
  let pageType = match.params.page_type;

  const RenderComponent = () => {
    let linkKey = localStorage.getItem("localLink");
    if (Helper.isEmpty(linkKey)) {
      pageType = "key";
    } else if (Helper.isEmpty(pageType)) {
      pageType = "chinese";
    } else {
      pageType = "chinese";
    }
    switch (pageType) {
      case "chinese":
        return (
          <LinkChinese history={history} location={location} pageId={pageId} />
        );
      default:
      case "key":
        return <LinkKey history={history} location={location} />;
    }
  };
  const LinkComponent = () => {
    return (
      <div className="w3-display-top" style={{ paddingBottom: "70px" }}>
        <div
          className="w3-bar w3-green"
          style={{ paddingBottom: "5px", paddingTop: "5px" }}
        >
          <NavLink to={"/" + pageId + "/chinese"}>
            <button className="w3-bar-item w3-button">
              하루한장 중국어1권
            </button>
          </NavLink>
        </div>
        <div className="w3-panel">{RenderComponent()}</div>
      </div>
    );
  };

  return <LinkComponent />;
}
export default Link;
