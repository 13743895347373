import React, { useState, useEffect, Fragment } from "react";

/* module */
import Dotdotdot from "react-dotdotdot";

/* common */
import * as Helper from "../common/Helper.js";

/* code data */

const LinkChinese = (props) => {
  useEffect(() => {
    getPostList();
  }, []);

  const [postList, setPostList] = useState([]);
  const getPostList = async () => {
    try {
      //'http://download.mirae-n.com/qr/onedaychina1/1_1/index.html'
      let a = [];

      for (let i = 1; i <= 8; i++) {
        //주
        for (let j = 1; j <= 5; j++) {
          //일
          let o = {
            title: i + "주 " + j + "일",
            url:
              "http://download.mirae-n.com/qr/onedaychina1/" +
              i +
              "_" +
              j +
              "/index.html",
          };
          a.push(o);
        }
      }
      setPostList(a);
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <Fragment>
      <div className="w3-center">
        <p>유진, 채영이를 위해 ♡사랑해</p>
        {postList &&
          postList.map((item, idx) => {
            return (
              <div
                key={idx}
                className={
                  "w3-panel w3-cell-row w3-border-top w3-border-bottom"
                }
                onClick={() => Helper.goPopup(item.url, "chinese")}
              >
                <div
                  key={"post_title_" + idx}
                  className="w3-left w3-cell w3-margin-top w3-margin-bottom link-cursor"
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <Dotdotdot clamp={1}>
                    &nbsp;
                    {decodeURIComponent(String(item.title).replace(/\+/g, " "))}
                  </Dotdotdot>
                </div>
              </div>
            );
          })}
      </div>
    </Fragment>
  );
};

export default LinkChinese;
