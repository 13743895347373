import React, { useEffect } from "react";

/* common */
import * as Helper from "../common/Helper.js";

/* module */

/* page */
import AlbumKey from "./AlbumKey";
import AlbumList from "./AlbumList";
import AlbumCreate from "./AlbumCreate";

/* css */

function Album({ match, history, location }) {
  const pageId = Helper.getPageId(match.url);
  let pageType = match.params.page_type;

  useEffect( () => {
    const contents = {
      Action: "open",
      PathName: location.pathname
    };
    Helper.setLog("album", contents); //page_id, contents
  }, []);

  const RenderComponent = () => {
    let albumKey = localStorage.getItem("localAlbum");
    if (Helper.isEmpty(pageType)) {
      pageType = "list"; 
    }
    if (Helper.isEmpty(albumKey)) {
      pageType = "key"; //앨범키가 없을 경우에는 초기 페이지를 설정한다.
    }
    
    switch (pageType) {
      case "list":
        return (
          <AlbumList
            history={history}
            location={location}
            albumKey={albumKey}
          />
        );

      case "create":
        return (
          <AlbumCreate
            history={history}
            location={location}
            albumKey={albumKey}
          />
        );

      default:
      case "key":
        return <AlbumKey history={history} location={location} />;
    }
  };

  const AlbumComponent = () => {
    return (
      <div className="w3-display-top" style={{ paddingBottom: "70px" }}>
        <div className="w3-panel">{RenderComponent()}</div>
      </div>
    );
  };

  return <AlbumComponent />;
}
export default Album;
