import React from "react";
import { NavLink } from "react-router-dom";

/* common */
import * as Helper from "../common/Helper.js";

/* page */

/* css */
import "../../styles/lotto.css";

function Todo({ match, history, location }) {
  const pageId = Helper.getPageId(match.url);
  const category = match.params.category;

  const RenderComponent = () => {
    switch (category) {
      // case "winning":
      //   return <LottoWinning history={history} location={location} />;
      case undefined:
      default:
        return null;
    }
  };
  const TodoComponent = () => {
    return (
      <div className="w3-display-top" style={{ paddingBottom: "70px" }}>
        <div
          className="w3-bar w3-green"
          style={{ paddingBottom: "5px", paddingTop: "5px" }}
        >
          <NavLink to={"/" + pageId + ""}>
            <button className="w3-bar-item w3-button">준비중</button>
          </NavLink>
        </div>
        <div className="w3-panel">준비중{RenderComponent()}</div>
      </div>
    );
  };

  return <TodoComponent />;
}
export default Todo;
