import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import history from '../../history';

/* common */
import * as Helper from "../common/Helper.js";
import * as NativeIF from "../common/NativeIF.js";

const Header = (props) => {
  const { pageId } = props;

  const [pageDesc, setPageDesc] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    //모바일 데이터로 접속 시  'data' 경고 노출
    if (pageId === "home" || pageId === "lotto" || pageId === "korail" || pageId === "subway" || pageId === "album" || pageId === "finance") {
      const isMobileData = window.sessionStorage.getItem("isMobileData");
      if (Helper.isEmpty(isMobileData) && NativeIF.fn_isMobileData()) {
        setIsMobile(NativeIF.fn_isMobileData());

        setTimeout(() => {
          setIsMobile(false);
          window.sessionStorage.setItem("isMobileData", true);
        }, 2500);
      }
    }

    let homepage = true;
    switch (pageId) {
      case "blog":
        document.title = document.title + " - 일상기록";
        setPageDesc(" :: 일상기록");
        break;
      case "portfolio":
        document.title = document.title + " - 프로젝트";
        setPageDesc(" :: 프로젝트");
        break;
      case "photo":
        document.title = document.title + " - 사진";
        setPageDesc(" :: 사진");
        break;
      case "coding":
        document.title = document.title + " - 코딩메모(tip, error note 등)";
        setPageDesc(" :: 코딩메모(tip, error note 등)");
        break;
      case "lotto_import":
        document.title = document.title + " - 로또 번호 생성";
        setPageDesc(" :: 로또 번호 생성");
        break;
      case "lotto":
        homepage = false;
        document.title = "로또 번호 생성";
        setPageDesc("로또 번호 생성");
        break;
      case "korail_import":
        document.title = document.title + " - 코레일 열차(기차) 정보";
        setPageDesc(" :: 코레일 열차(기차) 정보");
        break;
      case "korail":
        homepage = false;
        document.title = "코레일 열차(기차) 정보";
        setPageDesc("코레일 열차(기차) 정보");
        break;
      case "subway_import":
        document.title = document.title + " - 지하철(전철)역 실시간 도착 정보";
        setPageDesc(" :: 지하철(전철)역 실시간 도착 정보");
        break;
      case "subway":
        homepage = false;
        document.title = "지하철(전철)역 실시간 도착 정보";
        setPageDesc("지하철(전철)역 실시간 도착 정보");
        break;
      case "album_import":
      case "ccu_import":
        document.title = document.title + " - 사진 공유";
        setPageDesc(" :: 사진 공유");
        break;
      case "album":
      case "ccu":
        homepage = false;
        document.title = "사진 공유";
        setPageDesc("사진 공유");
        break;
      case "test":
        homepage = false;
        document.title = document.title + " - TEST";
        setPageDesc(" :: TEST");
        break;
      case "link":
        document.title = document.title + " - 하루한장";
        setPageDesc(" :: 하루한장");
        break;
      case "test":
        homepage = false;
        document.title = document.title + " - 테스트 코드 작성";
        setPageDesc(" :: 테스트 코드 작성");
        break;
      case "finance_import":
        document.title = document.title + " - 증권정보";
        setPageDesc(" :: 증권정보");
        break;
      case "finance":
        homepage = false;
        document.title = "증권정보";
        setPageDesc("증권정보");
        break;
      case "profit":
        document.title = document.title + " - 증권정보(H)";
        setPageDesc(" :: 증권정보(H)");
        break;
      case "login":
        document.title = document.title + " - 로그인";
        setPageDesc(" :: 로그인");
        break;
      case "home":
      default:
        homepage = false;
        document.title = "조찬양";
        break;
    }

    if (Helper.isSession()) {
      setIsLogin(true);
    }
    else {
      setIsLogin(false);
    }
  }, []);

  const RenderTopLeft = () => {
    if (pageId === "home") {
      return (
        <div className="w3-left">
          <button
            className="w3-bar-item w3-button w3-padding-large "
            style={{ width: "64px" }}
            onClick={() => history.push('/sitemap')}
            title="사이트맵"
          >
            <i className="fa fa-bars"></i>
          </button>
        </div>
      )
    }
    else if (
      pageId === "todo" ||
      pageId === "lotto" ||
      pageId === "album" ||
      pageId === "korail" ||
      pageId === "subway" ||
      pageId === "finance"
    ) {
      return null;
    } else {
      return (
        <div className="w3-left">
          <button
            className="w3-bar-item w3-button w3-padding-large "
            style={{ width: "64px" }}
            onClick={() => Helper.goBack(history)}
            title="뒤로가기"
          >
            <i className="fas fa-arrow-left"></i>
          </button>
        </div>
      );
    }
  };

  const RenderTopRight = () => {
    if (pageId === "home") {
      return (
        <div className="w3-right">
          <RenderUser />
        </div>
      );
    }
    else if (
      pageId === "lotto" ||
      pageId === "album" ||
      pageId === "korail" ||
      pageId === "todo" ||
      pageId === "subway" ||
      pageId === "finance"
    ) {
      return null;
    } else {
      return (
        <div className="w3-right">
          <button
            className="w3-bar-item w3-button w3-padding-large"
            style={{ width: "64px" }}
            onClick={() => history.push("/")}
            title="홈으로"
          >
            <i className="fa fa-home"></i>
          </button>
        </div>
      );
    }
  };

  const RenderTitleBar = () => {
    let title = "조찬양 - 세 아이 아빠의 일상기록";
    if (pageId === "lotto") {
      title = "로또 번호 생성기";
    } else if (pageId === "album") {
      title = "사진공유";
    } else if (pageId === "korail") {
      title = "코레일 열차(기차) 정보";
    } else if (pageId === "subway") {
      title = "지하철(전철)역 실시간 도착 정보";
    } else if (pageId === "finance") {
      title = "증권정보";
    } else if (pageId === "todo") {
      title = "오늘 할일";
    }

    return (
      <div className="w3-bar w3-card">
        <RenderTopRight />
        <RenderTopLeft />
        <div className="w3-padding-large w3-center">
          <span>{title}</span>
        </div>
      </div>
    );
  };


  const RenderUser = () => {
    let url = isLogin ? "/my" : "/login";
    let title = isLogin ? "사용자정보" : "로그인";
    let icon = isLogin ? "fas fa-user-cog" : "fa fa-user";

    return (
      <button
        className="w3-bar-item w3-button w3-padding-large "
        style={{ width: "64px" }}
        onClick={() => history.push(url)}
        title={title}
      >
        <i className={icon} ></i>
      </button>
    )
  }


  const RenderNavTitle = () => {
    if (
      pageId === "home" ||
      pageId === "lotto" ||
      pageId === "album" ||
      pageId === "todo" ||
      pageId === "korail" ||
      pageId === "subway" ||
      pageId === "finance"
    ) {
      return null;
    } else {
      return (
        <>
          <i className="fas fa-angle-right"></i>{" "}
          <NavLink to={"/" + pageId} style={{ fontWeight: "bold" }}>
            {pageId.indexOf("_import") >= 0
              ? pageId.replace("_import", "")
              : pageId}{" "}
            {pageDesc}
          </NavLink>
        </>
      );
    }
  };

  return (
    <>
      <div className="w3-top w3-white">
        <RenderTitleBar />
      </div>

      <div className="w3-top w3-center w3-yellow" style={{ display: (isMobile ? 'block' : 'none') }}>
        <p>[모바일 데이터]를 사용중입니다.</p>
      </div>

      <header
        className="w3-panel"
        style={{
          paddingTop:
            pageId === "lotto" ||
              pageId === "album" ||
              pageId === "korail" ||
              pageId === "subway" ||
              pageId === "todo" ||
              pageId === "finance"
              ? "16px"
              : "45px",
        }}
      >
        <RenderNavTitle />
      </header>
    </>
  );
};

export default Header;
