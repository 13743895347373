import React, { useEffect } from "react";

/* module */
import axios from "axios";

function Logout({ history }) {
  
  useEffect( () => {    
    logout();
  }, []);

  const logout = async () => {  
    await axios.get("/api/login/logout").catch( (e) => {  console.log(e); });
    window.sessionStorage.clear();
    window.localStorage.removeItem("localLoginID");
    history.push("/");
  };

  return null;
}
export default Logout;
