import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PopupAction from '../../../store/modules/popup';

/* module */
import axios from "axios";

/* common */
import * as Helper from "../../common/Helper.js";

/* css */

const Check = ({ PopupData, PopupAction }) => {
  const {
    popup: { quickBtn, title, msg, confirmBtnMsg, cancelBtnMsg, confirmFunction, cancelFunction }
  } = PopupData;
  const { setInitPopup } = PopupAction;

  function handleConfirmBtn() {
    confirmFunction && confirmFunction();
    setInitPopup();
  }

  function handleCancelBtn() {
    cancelFunction && cancelFunction();
    setInitPopup();
  }

  return (
    <div className="popup-wrap">
      <div className="popup text">
        <div className="pop-header">{title}</div>
        <div className="pop-container">
          <div className="pop-content text">
            <p className="pop-text-main">{msg}</p>
          </div>
        </div>
        <div className="button-container" onClick={handleConfirmBtn}>
          <button className="btn btn-secondary" onClick={handleCancelBtn}>
            {cancelBtnMsg}
          </button>
          
          <button
            className={`btn btn-primary`}
            onClick={handleConfirmBtn}
          >
            {confirmBtnMsg}
          </button>
        </div>
        {quickBtn && <button className="pop-close" onClick={handleCancelBtn}></button>}
      </div>
    </div>
  );
};

export default connect(
  state => ({
    PopupData: state.Popup
  }),
  dispatch => ({
    PopupAction: bindActionCreators(PopupAction, dispatch)
  })
)(Check);