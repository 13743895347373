import React, { useEffect, useState } from "react";
import ScriptTag from 'react-script-tag';

/* common */
import * as Helper from "../common/Helper.js";
import * as NativeIF from "../common/NativeIF.js";

const Footer = ({ pageId }) => {
  let ad_company = "coupang";
  const [topLinkOpen, setTopLinkOpen] = useState(false);
  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (topLinkOpen && window.pageYOffset < 0) {
        setTopLinkOpen(false);
      } else if (!topLinkOpen && window.pageYOffset > 0) {
        setTopLinkOpen(true);
      } else if (window.pageYOffset === 0) {
        setTopLinkOpen(false);
      }
    }
  }, []);

  const RenderTop = () => {
    let top = "100px";
    if(NativeIF.fn_isMobileData() || ad_company === "coupang") {        //쿠팡
      if(window.innerWidth >= 728) {
        top = "100px";
      }
      else {
        top = (window.innerWidth/728);
        top = parseInt(90 * top) + 10 + "px";
      }
    }
    else {
      if(window.innerWidth < 728) {
        top = "60px";
      }
    }

    return (
      <div id="top-link" className={"w3-display-bottomright " + (topLinkOpen ? "w3-show" : "w3-hide")} style={{marginBottom:top, position:"fixed"}}>
        <i
          className="far fa-arrow-alt-circle-up w3-button w3-xlarge w3-hover-none"
          onClick={() => Helper.moveScroll(0)}
        />
      </div>
    )
  }

  const RenderAd = () => {
    if(NativeIF.fn_isMobileData() || ad_company === "coupang") {
      return (
        <div id="ad">
          <a href="https://coupa.ng/bQvFTO" target="_blank" referrerPolicy="unsafe-url">
            <img src="https://ads-partners.coupang.com/banners/428431?subId=&traceId=V0-301-879dd1202e5c73b2-I428431&w=728&h=90" alt=""/>
          </a>
        </div> 
      )
    }
    else {
      if(window.innerWidth >= 728) {
        return (
          <div id="ad">
            <ins className="kakao_ad_area" style={{display:"none"}} data-ad-unit={"DAN-BNfq9Z6FaFpEqGHm"} data-ad-width={"728"} data-ad-height={"90"}></ins> 
            <ScriptTag type="text/javascript" src="//t1.daumcdn.net/kas/static/ba.min.js" async></ScriptTag>
          </div>
        )
      }
      else {
        return (
          <div id="ad">
            <ins className="kakao_ad_area" style={{display:"none"}} data-ad-unit={"DAN-RSfWoxGPoqiYxpvR"} data-ad-width={"320"} data-ad-height={"50"}></ins> 
            <ScriptTag type="text/javascript" src="//t1.daumcdn.net/kas/static/ba.min.js" async></ScriptTag>
          </div>
        )
      }
    }
  }

  return (
    <>
      <RenderTop />
      <footer className={"w3-center w3-light-grey w3-bottom"} style={{paddingTop:"0px"}}>
        <RenderAd />
      </footer>
    </>
  );
};
//
export default Footer;
