/* common */
import * as Helper from "../common/Helper.js";

export const setSubwayFav = (arr) => {
  //fas ♥  , far ♡
  let lc = JSON.parse(localStorage.getItem("localSubwayFav"));

  let favId =
    String(arr.line_num) +
    "/" +
    String(arr.station_cd) +
    "/" +
    String(arr.station_nm);

  if (document.getElementById(favId).className.indexOf("fas fa-heart") >= 0) {
    //배열에서 arr을 찾아야 함
    //배열에서 해당 번호 삭제
    //재등록
    if (lc && lc.length >= 1) {
      //localStorage.removeItem("localSubwayFav");

      //동일차량이 있는지 체크
      let temp = [];
      lc &&
        lc.map((item) => {
          let chk1 =
            String(item.line_num) +
            "/" +
            String(item.station_cd) +
            "/" +
            String(item.station_nm);
            
          let chk2 =
            String(arr.line_num) +
            "/" +
            String(arr.station_cd) +
            "/" +
            String(arr.station_nm);
          if (chk1.indexOf(chk2) === -1) {
            temp.push(item);
          }
        });

      temp && localStorage.setItem("localSubwayFav", JSON.stringify(temp));
      document.getElementById(favId).className = "far fa-heart link-cursor";
    }
  } else {
    lc &&
      lc.map((item) => {
        let chk1 =
          String(item.line_num) +
          "/" +
          String(item.station_cd) +
          "/" +
          String(item.station_nm);
        let chk2 =
          String(arr.line_num) +
          "/" +
          String(arr.station_cd) +
          "/" +
          String(arr.station_nm);

        if (chk1 === chk2) {
          alert("동일한 지하철역/노선이 이미 [자주가는 역]에 있습니다.");
          return;
        }
      });

    let arrNew = [arr];
    if (lc && lc.length > 0) {
      arrNew = arrNew.concat(lc);
    }

    if (arrNew && arrNew.length >= 1) {
      localStorage.setItem("localSubwayFav", JSON.stringify(arrNew));
      document.getElementById(favId).className = "fas fa-heart link-cursor";
    }
  }

  const UserInfo = Helper.getSession();
  if(Helper.isSession() && UserInfo && UserInfo.LoginId) {
    Helper.setLocalstorage("localSubwayFav", UserInfo.LoginId);
  }  
};

export const getSubwayFavStyle = (arr) => {
  let retValue = "far fa-heart";

  const lc = JSON.parse(localStorage.getItem("localSubwayFav"));
  let temp = [];
  lc &&
    lc.map((item) => {
      temp.push(
        String(item.line_num) +
          "/" +
          String(item.station_cd) +
          "/" +
          String(item.station_nm)
      );
    });
  if (
    temp.includes(
      String(arr.line_num) +
        "/" +
        String(arr.station_cd) +
        "/" +
        String(arr.station_nm)
    )
  ) {
    retValue = "fas fa-heart";
  }
  return retValue;
};
