/* common */
import * as Helper from "../common/Helper.js";

export const setKorailFav = (arr) => {
  //fas ♥  , far ♡
  let lc = JSON.parse(localStorage.getItem("localKorailFav"));

  if (arr.depplandtime.length > 5) {
    arr.depplandtime =
      String(arr.depplandtime).substring(8, 10) +
      ":" +
      String(arr.depplandtime).substring(11, 13);
    arr.arrplandtime =
      String(arr.arrplandtime).substring(8, 10) +
      ":" +
      String(arr.arrplandtime).substring(11, 13);
  }

  let favId =
    String(arr.depplacename) +
    "/" +
    String(arr.arrplacename) +
    "/" +
    String(arr.depplandtime) +
    "/" +
    String(arr.trainno);

  if (document.getElementById(favId).className.indexOf("fas fa-heart") >= 0) {
    //배열에서 arr을 찾아야 함
    //배열에서 해당 번호 삭제
    //재등록
    if (lc && lc.length >= 1) {
      //localStorage.removeItem("localKorailFav");

      //동일차량이 있는지 체크
      let temp = [];
      lc &&
        lc.map((item) => {
          if (item.depplandtime.length > 5) {
            item.depplandtime =
              String(item.depplandtime).substring(8, 10) +
              ":" +
              String(item.depplandtime).substring(11, 13);
            item.arrplandtime =
              String(item.arrplandtime).substring(8, 10) +
              ":" +
              String(item.arrplandtime).substring(11, 13);
          }

          let chk1 =
            String(item.depplacename) +
            "/" +
            String(item.arrplacename) +
            "/" +
            String(item.depplandtime) +
            "/" +
            String(item.trainno);
          let chk2 =
            String(arr.depplacename) +
            "/" +
            String(arr.arrplacename) +
            "/" +
            String(arr.depplandtime) +
            "/" +
            String(arr.trainno);
          if (chk1.indexOf(chk2) === -1) {
            temp.push(item);
          }
        });

      temp && localStorage.setItem("localKorailFav", JSON.stringify(temp));
      document.getElementById(favId).className = "far fa-heart link-cursor";
    }
  } else {
    lc &&
      lc.map((item) => {
        if (item.depplandtime.length > 5) {
          item.depplandtime =
            String(item.depplandtime).substring(8, 10) +
            ":" +
            String(item.depplandtime).substring(11, 13);
          item.arrplandtime =
            String(item.arrplandtime).substring(8, 10) +
            ":" +
            String(item.arrplandtime).substring(11, 13);
        }

        let chk1 =
          String(item.depplacename) +
          "/" +
          String(item.arrplacename) +
          "/" +
          String(item.depplandtime) +
          "/" +
          String(item.trainno);
        let chk2 =
          String(arr.depplacename) +
          "/" +
          String(arr.arrplacename) +
          "/" +
          String(arr.depplandtime) +
          "/" +
          String(arr.trainno);

        if (chk1 === chk2) {
          alert("동일한 차량이 이미 [자주타는 열차]에 있습니다.");
          return;
        }
      });

    let arrNew = [arr];
    if (lc && lc.length > 0) {
      arrNew = arrNew.concat(lc);
    }

    if (arrNew && arrNew.length >= 1) {
      localStorage.setItem("localKorailFav", JSON.stringify(arrNew));
      document.getElementById(favId).className = "fas fa-heart link-cursor";
    }
  }

  const UserInfo = Helper.getSession();
  if(Helper.isSession() && UserInfo && UserInfo.LoginId) {
    Helper.setLocalstorage("localKorailFav", UserInfo.LoginId);
  }
};

export const getKorailFavStyle = (arr) => {
  if (arr.depplandtime.length > 5) {
    arr.depplandtime =
      String(arr.depplandtime).substring(8, 10) +
      ":" +
      String(arr.depplandtime).substring(11, 13);
    arr.arrplandtime =
      String(arr.arrplandtime).substring(8, 10) +
      ":" +
      String(arr.arrplandtime).substring(11, 13);
  }

  let retValue = "far fa-heart";

  const lc = JSON.parse(localStorage.getItem("localKorailFav"));
  let temp = [];
  lc &&
    lc.map((item) => {
      if (item.depplandtime.length > 5) {
        item.depplandtime =
          String(item.depplandtime).substring(8, 10) +
          ":" +
          String(item.depplandtime).substring(11, 13);
        item.arrplandtime =
          String(item.arrplandtime).substring(8, 10) +
          ":" +
          String(item.arrplandtime).substring(11, 13);
      }

      temp.push(
        String(item.depplacename) +
          "/" +
          String(item.arrplacename) +
          "/" +
          String(item.depplandtime) +
          "/" +
          String(item.trainno)
      );
    });
  if (
    temp.includes(
      String(arr.depplacename) +
        "/" +
        String(arr.arrplacename) +
        "/" +
        String(arr.depplandtime) +
        "/" +
        String(arr.trainno)
    )
  ) {
    retValue = "fas fa-heart";
  }
  return retValue;
};
