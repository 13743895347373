import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import * as PopupAction from '../../store/modules/popup';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

/* module */
import axios from "axios";

/* common */
import * as Helper from "../common/Helper.js";

/* css */
import "../../styles/home.css";

function Home({ match, history, location, PopupAction, PopupData }) {
  
  const HomeComponent = () => {
    useEffect( () => {
      const loginId = window.localStorage.getItem("localLoginID");
      if(!Helper.isEmpty(loginId) && !Helper.isSession()) {
        autoLogin(loginId);
      }

      const contents = {
        Action: "open",
        PathName: location.pathname
      };
      Helper.setLog("home", contents); //page_id, contents
    }, []);

    const autoLogin = async (loginId) => {
      await axios.post("/api/login/login", {
        login_id: loginId,
        auto_login: true
      }).then((retData) => {
        if (!Helper.isEmpty(retData.data)) {
          const data = retData.data;
          if (data.statusCode === 200) {
            if (data.retData === "LoginOK" || data.retData === "NEED TO CHANGE PASSWORD") {
              Helper.loginPass(data, loginId, history);
            }
            else {
              console.log("autoLogin error", data.retMsg);
              window.sessionStorage.clear();
              window.localStorage.removeItem("localLoginID");
            }
          } 
          else {
            console.log("autoLogin error", data.retMsg);
            window.sessionStorage.clear();
            window.localStorage.removeItem("localLoginID");
          }
        }
        else {
          console.log("autoLogin error", retData);
          window.sessionStorage.clear();
          window.localStorage.removeItem("localLoginID");
        }
      }).catch( (e) => {
        console.log("autoLogin error", e);
        window.sessionStorage.clear();
        window.localStorage.removeItem("localLoginID");
      });
    }

    const goNamuWiki = () => {
      const SearchWord = document.querySelector("#SearchWord").value;
      Helper.goPopup("https://namu.wiki/w/" + SearchWord, "namu.wiki");
    }

    
    function handleKeyEvent(e) {
      const value = e.target.value;
      if (Helper.isEmpty(value)) {
        return;
      }

      switch (e.type) {
        case 'keydown':
          if (e.keyCode === 13) {
            goNamuWiki();
          }
          break;
        
        default:
          break;
      }
    }

    const RenderSessionMenu = () => {
      if(Helper.isSession()) {
        return (
          <>  
          <div className="w3-border-0 w3-cell home-main-btn">
            <NavLink to={"/profit"}>
              <i className="fas fa-chart-line w3-hover-opacity w3-xxlarge" />
            </NavLink>
            <div className="w3-center" style={{paddingTop:"14px", paddingBottom:"14px"}}>
              <NavLink to={"/profit"}>profit<br />::증권정보(H)</NavLink>
            </div>
          </div>

          <div className="w3-border-0 w3-cell home-main-btn">
            <NavLink to={"/link"}>
              <i className="fas fa-link w3-hover-opacity w3-xxlarge" />              
            </NavLink>
            <div className="w3-center" style={{paddingTop:"14px", paddingBottom:"14px"}}>
              <NavLink to={"/link"}>link<br />::하루한장</NavLink>
            </div>
          </div>

          <div className="w3-border-0 w3-cell home-main-btn">
            <i className="fas fa-cloud-sun-rain w3-hover-opacity w3-xxlarge" onClick={() => Helper.goPopup("https://weather.naver.com", "weather")}/>   
            <div className="w3-center" style={{paddingTop:"14px", paddingBottom:"14px"}}>
              <a href="#" onClick={() => Helper.goPopup("https://weather.naver.com", "weather")}>weather<br />::날씨</a>
            </div>
          </div>

          <div className="w3-border-0 w3-cell home-main-btn">
            <NavLink to={"/insta"}>
              <i className="fab fa-instagram w3-hover-opacity w3-xxlarge" />              
            </NavLink>
            <div className="w3-center" style={{paddingTop:"14px", paddingBottom:"14px"}}>
              <NavLink to={"/insta"}>instagram<br />::인스타그램　</NavLink>
            </div>
          </div>

          <div className="w3-border-0 w3-cell home-main-btn">
            <NavLink to={"/test"}>
              <i className="fas fa-wrench w3-hover-opacity w3-xxlarge" />              
            </NavLink>
            <div className="w3-center" style={{paddingTop:"14px", paddingBottom:"14px"}}>
              <NavLink to={"/test"}>test<br />::테스트　</NavLink>
            </div>
          </div>
          </>
        )
      }
      else {
        return (
          <>  
          </>
        )
      }
    }

    const RenderCoupang = () => {
      return (
          <a href="https://coupa.ng/bQvFTO" target="_blank" referrerpolicy="unsafe-url">
            <img src="https://ads-partners.coupang.com/banners/428431?subId=&traceId=V0-301-879dd1202e5c73b2-I428431&w=728&h=90" alt=""/>
          </a>
      )
    }

    return (
      <div className="w3-center">
        <div
          className="w3-center"
          style={{ width: "100%", paddingBottom: "15px", float:"left" }}
        >
          <div className="w3-container  w3-cell w3-center" style={{width:"100%"}}>
            <input className="w3-input w3-border" type="text" id="SearchWord" placeholder="나무위키 검색" onKeyDown={handleKeyEvent} />
          </div>

          <div className="w3-container  w3-cell w3-center">
            <i className="fa fa-search" style={{fontSize:"x-large", paddingTop:"10px"}} onClick={goNamuWiki}></i>
          </div>
        </div>

        <div className="w3-center w3-cell-row" style={{paddingTop: "15px", display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
          <div className="w3-border-0 w3-cell home-main-btn">
            <NavLink to={"/blog"}>
              <i className="fab fa-blogger w3-hover-opacity w3-xxlarge" />              
            </NavLink>
            <div className="w3-center" style={{paddingTop:"14px", paddingBottom:"14px"}}>
              <NavLink to={"/blog"}>blog<br />::일상기록</NavLink>
            </div>
          </div>
          <div className="w3-border-0 w3-cell home-main-btn">
            <NavLink to={"/photo"}>
              <i className="fas fa-camera-retro w3-hover-opacity w3-xxlarge" />              
            </NavLink>
            <div className="w3-center" style={{paddingTop:"14px", paddingBottom:"14px"}}>
              <NavLink to={"/photo"}>photo<br />::사진　　</NavLink>
            </div>
          </div>
          <div className="w3-border-0 w3-cell home-main-btn">
            <NavLink to={"/portfolio"}>
              <i className="fas fa-tasks w3-hover-opacity w3-xxlarge" />              
            </NavLink>
            <div className="w3-center" style={{paddingTop:"14px", paddingBottom:"14px"}}>
              <NavLink to={"/portfolio"}>portfolio<br />::프로젝트</NavLink>
            </div>
          </div>
          <div className="w3-border-0 w3-cell home-main-btn">
            <NavLink to={"/coding"}>
              <i className="fas fa-code w3-hover-opacity w3-xxlarge" />              
            </NavLink>
            <div className="w3-center" style={{paddingTop:"14px", paddingBottom:"14px"}}>
              <NavLink to={"/coding"}>coding<br />::코딩메모</NavLink>
            </div>
          </div>
          <div className="w3-border-0 w3-cell home-main-btn">
            <NavLink to={"/album_import"}>
              <i className="fas fa-users w3-hover-opacity w3-xxlarge" />              
            </NavLink>
            <div className="w3-center" style={{paddingTop:"14px", paddingBottom:"14px"}}>
              <NavLink to={"/album_import"}>album<br />::사진공유</NavLink>
            </div>
          </div>
          <div className="w3-border-0 w3-cell home-main-btn">
            <NavLink to={"/lotto_import"}>
              <i className="fas fa-dot-circle w3-hover-opacity w3-xxlarge" />              
            </NavLink>
            <div className="w3-center" style={{paddingTop:"14px", paddingBottom:"14px"}}>
              <NavLink to={"/lotto_import"}>lotto<br />::로또번호</NavLink>
            </div>
          </div>
          <div className="w3-border-0 w3-cell home-main-btn">
            <NavLink to={"/korail_import"}>
              <i className="fas fa-train w3-hover-opacity w3-xxlarge" />              
            </NavLink>
            <div className="w3-center" style={{paddingTop:"14px", paddingBottom:"14px"}}>
              <NavLink to={"/korail_import"}>korail<br />::열차정보</NavLink>
            </div>
          </div>
          <div className="w3-border-0 w3-cell home-main-btn">
            <NavLink to={"/subway_import"}>
              <i className="fas fa-subway w3-hover-opacity w3-xxlarge" />              
            </NavLink>
            <div className="w3-center" style={{paddingTop:"14px", paddingBottom:"14px"}}>
              <NavLink to={"/subway_import"}>subway<br />::지하철역</NavLink>
            </div>
          </div>
          <div className="w3-border-0 w3-cell home-main-btn">
            <NavLink to={"/finance_import"}>
              <i className="fas fa-chart-line w3-hover-opacity w3-xxlarge" />
            </NavLink>
            <div className="w3-center" style={{paddingTop:"14px", paddingBottom:"14px"}}>
              <NavLink to={"/finance_import"}>finance<br />::증권정보</NavLink>
            </div>
          </div>        
          <RenderSessionMenu />
        </div>
        {/* <RenderCoupang /> */}
      </div>
    );
  };

  return <HomeComponent />;
}

// 컴포넌트에 리덕스 스토어를 연동해줄 때에는 connect 함수 사용
export default connect(
  state => ({
    PopupData: state.Popup.popup
  }),
  dispatch => ({
    PopupAction: bindActionCreators(PopupAction, dispatch)
  })
)(Home);
