import React from "react";
import { Router } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import history from "./history";

function App() {
  return (
    <Router history={history}>
      <AppRoutes />
    </Router>
  );
}

export default App;
