import React, { useEffect, useState } from "react";
import * as NativeIF from "../common/NativeIF.js";

import * as PopupAction from '../../store/modules/popup';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const TestBlank = ({PopupAction, PopupData}) => {  
  useEffect( () => {
    
  }, []);

  const fnIsApp = () => {    
    document.querySelector("#result").value = '';
    document.querySelector("#result").value = NativeIF.fn_isApp();
  }
  
  const fnIsMobileData = () => {    
    document.querySelector("#result").value = '';
    document.querySelector("#result").value = NativeIF.fn_isMobileData();
  }

  const fnGetNetwork = () => {
    document.querySelector("#result").value = '';
    document.querySelector("#result").value = NativeIF.fn_getNetwork();
  }

  const fnAlert = (value) => {
    alert(value);
  }

  const fnPopupClose = () => {
    console.log('onClose')
    PopupAction.setInitPopup();
  }

  const fnPopupOpen = (type) => {
    console.log('onOpen')
    const target = document.querySelector("#popup");
    if(type === 'simple') {
      PopupAction.setPopupContent({
        ...PopupData,
        type: type,
        bgLock: false,
        title: '심플팝업',
        msg: '메세지',
        confirmBtnMsg: '확인',
        confirmFunction: () => {}
      });
      if(target)  {
        target.style = "display:block";
      }
    }
    else if(type === 'check') {
      PopupAction.setPopupContent({
        ...PopupData,
        type: type,
        title: '심플팝업',
        msg: '메세지',
        confirmBtnMsg: '확인',
        cancelBtnMsg: '취소',
        confirmFunction: () => {},
        cancelFunction: () => {}
      });
      if(target)  {
        target.style = "display:none";
      }
    }
  }

  return (
    <div className="w3-container">
      <div>
        <p>native i/f test</p>
        <button onClick={() => fnIsApp()}>IsApp</button>
        <button onClick={() => fnIsMobileData()}>IsMobileData</button>
        <button onClick={() => fnGetNetwork()}>GetNetwork</button>
        <button onClick={() => fnAlert('alert 테스트')}>alert test</button>
        <input type="text" id="result" />
      </div>
      
      <div>
        <p>popup test</p>
        <button onClick={() => fnPopupClose()}>popup close</button>
        <button onClick={() => fnPopupOpen('simple')}>popup open(simple)</button>
        <button onClick={() => fnPopupOpen('check')}>popup open(check)</button>
        <button onClick={() => fnPopupOpen('error')}>popup open(error)</button>
      </div>
    </div>
  );
};


// 컴포넌트에 리덕스 스토어를 연동해줄 때에는 connect 함수 사용
export default connect(
  state => ({
    PopupData: state.Popup.popup
  }),
  dispatch => ({
    PopupAction: bindActionCreators(PopupAction, dispatch)
  })
)(TestBlank);

