export const fn_isApp = () => {
    try {
        if(window.location.hostname.indexOf("localhost") >= 0) {
            return true;
        }

        if (window.aos.isApp() === true) {
            return true;
        } 
        else {
            return false;
        }
    } 
    catch (e) {
        return false;
    }
}

export const fn_isMobileData = () => {    
    try {
        if(window.location.hostname.indexOf("localhost") >= 0) {
            return false;
        }

        if(fn_isApp()) {
            if (window.aos.isMobileData() === true) {
                return true;
            } 
            else {
                return false;
            }
        }
        else {
            //console.log("앱이 아닙니다.");
            return false;
        }
    } 
    catch (e) {
        console.log('fn_isMobileData error : ', e);
        return false;
    }
}

export const fn_getNetwork = () => {
    let retValue = "";
    try {        
        if(fn_isApp()) {
            retValue = window.aos.getNetwork();
        }
        else {
            //console.log("앱이 아닙니다.");
            retValue = "NotApp";
        }
    } 
    catch (e) {
        console.log('fn_getNetwork error : ', e);
    }
    return retValue;
}