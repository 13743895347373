import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { useDispatch, useStore } from 'react-redux';
import { setCallbackInitPopup, setInitPopup } from '../store/modules/popup';

// components
import Home from "./home/Home";
import Lotto from "./lotto/Lotto";
import Blog from "./blog/Blog";
import Test from "./test/Test";
import SiteMap from "./common/SiteMap";
import Privacy from "./rule/Privacy";
import Korail from "./korail/Korail";
import Subway from "./subway/Subway";
import Album from "./album/Album";
import Login from "./login/Login";
import Logout from "./login/Logout";
import Link from "./link/Link";
import Todo from "./todo/Todo";
import Finance from "./finance/Finance";
import Profit from "./profit/Profit";
import Insta from "./insta/Insta";

/* css */
import "../styles/common.css";


export default (props) => {  
  const store = useStore();
  const dispatch = useDispatch();
  const popupData = store.getState().Popup.popup;

  // 새로고침하거나 화면 이동시 팝업 초기화
  useEffect(() => {
    if (props.location.pathname !== null && popupData.type !== 'toast') {
      closePopup();
    }
  }, [props.location.pathname]);

  function closePopup() {
    if (popupData.open) {
      dispatch(setInitPopup());
    }
  }

  return (
    <div id="content" style={{paddingBottom:"15px"}}>
      <Route exact component={Test}    path={["/test", "/test/:page_type"]} />

      <Route exact component={Home}    path={["/", "/home"]} />
      <Route exact component={Blog}    path={[  "/blog",
                                                                      "/blog/:category",
                                                                      "/blog/:category/:log_no",
                                                                      "/photo",
                                                                      "/photo/:log_no",
                                                                      "/portfolio",
                                                                      "/portfolio/:log_no",
                                                                      "/coding",
                                                                      "/coding/:category",
                                                                      "/coding/:category/:log_no" ]} />      
      <Route exact component={Link}    path={["/link", "/link/:page_type"]}/>
      <Route exact component={Login}   path={["/login", "/login/:page_type", "/my", "/my/:page_type"]} />
      <Route exact component={Logout}  path={["/logout"]}/>
      <Route exact component={Privacy} path={["/privacy"]} />
      <Route exact component={SiteMap} path={["/sitemap"]} />

      <Route exact component={Lotto}   path={["/lotto", "/lotto/:category", "/lotto_import", "/lotto_import/:category"]} />
      <Route exact component={Korail}  path={["/korail", "/korail/:page_type", "/korail_import", "/korail_import/:page_type"]} />
      <Route exact component={Subway}  path={["/subway", "/subway/:page_type", "/subway_import", "/subway_import/:page_type"]} />
      <Route exact component={Album}   path={[  "/album", 
                                                                      "/album/:page_type", 
                                                                      "/ccu", 
                                                                      "/ccu/:page_type", 
                                                                      "/album_import", 
                                                                      "/album_import/:page_type", 
                                                                      "/ccu_import", 
                                                                      "/ccu_import/:page_type"]} />
      <Route exact component={Finance} path={[  "/finance", 
                                                                      "/finance/:page_type", 
                                                                      "/finance/:page_type/:cd", 
                                                                      "/finance_import", 
                                                                      "/finance_import/:page_type", 
                                                                      "/finance_import/:page_type/:cd"]} />      
      <Route exact component={Profit} path={[  "/profit", 
                                                                      "/profit/:page_type", 
                                                                      "/profit/:page_type/:cd"]} />
      <Route exact component={Insta} path={[  "/insta", 
                                                                      "/insta/:page_type", 
                                                                      "/insta/:page_type/:cd", 
                                                                      "/insta_import", 
                                                                      "/insta_import/:page_type", 
                                                                      "/insta_import/:page_type/:cd"]} />      
      <Route exact component={Todo}    path={["/todo", "/todo/:page_type", "/todo_import", "/todo_import/:page_type"]} />
      
    </div>
  );
};
