import React, { useState, useEffect } from "react";

/* module */
import axios from "axios";
import Dotdotdot from "react-dotdotdot";
import parser from "html-react-parser";

/* common */
import * as Helper from "../common/Helper.js";

/* css */
import "../../styles/blog.css";

const BlogView = (props) => {
  useEffect(() => {
    getPostView(props.logNo);
  }, []);

  const [postTitle, setPostTitle] = useState("");
  const [postContent, setPostContent] = useState("");
  const getPostView = async (logNo) => {
    try {
      let url =
        "/api/blog/getPostView?isMobile=" +
        Helper.isMobile() +
        "&logNo=" +
        logNo;
      const { data } = await axios.get(url);

      if (data) {
        if (data.statusCode === 200) {
          //console.log("axios success");
          setPostTitle(data.retTitle);
          setPostContent(data.retContent);
        } else {
          //console.log("axios error");
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <>
      <div
        className="w3-card-4 w3-margin w3-white"
        style={{ paddingBottom: "70px" }}
      >
        <div className="w3-container w3-blue">
          <h4>{postTitle}</h4>
        </div>
        <div className="w3-container w3-border-bottom w3-padding">
          <Dotdotdot clamp={1}>
            <span
              onClick={() => {
                Helper.goPopup(
                  "https://blog.naver.com/jochanyang/" + props.logNo,
                  "naver_blog"
                );
              }}
              className="w3-text-blue link-cursor"
            >
              <i className="fab fa-blogger w3-hover-opacity w3-large"></i>
              &nbsp;http://blog.naver.com/jochanyang/{props.logNo}
            </span>
          </Dotdotdot>
        </div>
        <div className="w3-container w3-padding">{parser(postContent)}</div>
      </div>
    </>
  );
};

export default BlogView;
