import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";

/* module */
import axios from "axios";
import moment from "moment";

/* common */
import * as Helper from "../common/Helper.js";

const InstaList = (props) => {
  String.prototype.replaceAll = function (org, dest) {
    return this.split(org).join(dest);
  };

  
  const [time, setTime] = useState("");

  useEffect(() => {
    //local storage의 구매 리스트 받아오기
    getLocalInstaPrchList();

  }, []);

  const [financeList, setInstaList] = useState([]);

  //전체 조회(처음 화면 진입시)
  const getLocalInstaPrchList = () => {    
    //현재 시간 표시
    setTime(moment(new Date()).format("HH:mm:ss"));

    let data = JSON.parse(localStorage.getItem("localInstaPrch"));
    if (Helper.isEmpty(data)) {
      setInstaList([]);
      return;
    }

    let param = "";
    data && data.map((obj) => {
      if(param.length === 0) {
        param = obj.cd;
      }
      else {
        param = param + "," + obj.cd;
      }
    });

    if(param && param.length > 0) {
      axios
      .get("/api/finance/getRealtimeList?code=" + param)
      .then((data) => {
        if ( data && data.data && data.data.retData) {          
          let retData = data.data.retData;
          getInstaPrchList(retData);
        }
        else {
          setInstaList([]);
        }
      });
    }
    else {
      setInstaList([]);
    }
  };

  const getInstaPrchList = (favList) => {
    
  }

  return (
    <Fragment>
      <div className="w3-center">
      
      </div>
    </Fragment>
  );
};

export default InstaList;
