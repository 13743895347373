import React, { Fragment, useState, useEffect } from "react";

/* module */
import axios from "axios";
import parser from "html-react-parser";
import InfiniteScroll from "react-infinite-scroll-component";

/* common */
//import * as Helper from "../common/Helper.js";

function LottoWinning(props) {
  const pagePerCnt = 15; //1page에 보여질 갯수
  const [listWinning, setListWinning] = useState([]);
  const getListWinning = async (pDrawNoStart, pDrawNoEnd, infinite) => {
    if (pDrawNoStart === undefined || pDrawNoEnd === undefined) return;

    setDrawNoStart(pDrawNoStart);
    setDrawNoEnd(pDrawNoEnd);
    try {
      let url =
        "/api/lotto/getListWinning?drawNoStart=" +
        pDrawNoStart +
        "&drawNoEnd=" +
        pDrawNoEnd;
      const { data } = await axios(url);
      if (data) {
        if (data.statusCode === 200) {
          if (infinite) {
            setListWinning(listWinning.concat(data.retData));
          } else {
            setListWinning(data.retData);
          }
        } else {
          setListWinning([]); //오류 발생 시 [] 값 return
        }
      }
    } catch (e) {
      console.log("error", e);
      setListWinning([]); //오류 발생 시 [] 값 return
    }
  };

  const [drawNoStart, setDrawNoStart] = useState(0);
  const [drawNoEnd, setDrawNoEnd] = useState(0);
  const [maxWinning, setMaxWinning] = useState(0);
  const getMaxWinning = async () => {
    setListWinning([]); //화면 첫 로딩 후에는 null로 시작 한다.

    try {
      let url = "/api/lotto/getMaxWinning";
      const { data } = await axios(url);

      if (data) {
        if (data.statusCode === 200) {
          setMaxWinning(data.retData);
        }
      } else {
        console.log("data is undefined");
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    if (maxWinning !== 0) {
      //maxWinning가 0이 아닌 경우에만 실행 (0일 경우에는 오류 발생의 경우임)
      document.getElementById("drawNoStart").value = maxWinning - pagePerCnt;
      document.getElementById("drawNoEnd").value = maxWinning;

      getListWinning(maxWinning - pagePerCnt, maxWinning, false);
    }
  }, [maxWinning]);

  useEffect(() => {
    getMaxWinning();
  }, []);

  const fetchMoreData = () => {
    console.log("fetchMoreData");
    // a fake async api call like which sends
    if (listWinning.length < maxWinning) {
      setTimeout(() => {
        //데이터 불러오는 메세지 출력하기 위해 지연 효과를 줌
        getListWinning(drawNoStart - pagePerCnt, drawNoStart - 1, true);
      }, 150);

      document.getElementById("drawNoStart").value = drawNoStart - pagePerCnt;
    }
  };

  return (
    <Fragment>
      <div className="w3-panel w3-light-gray w3-padding w3-cell-row">
        <input
          type="number"
          id="drawNoStart"
          style={{ width: "25%" }}
          maxLength={4}
          min="1"
          max={drawNoEnd}
        />{" "}
        ~{" "}
        <input
          type="number"
          id="drawNoEnd"
          style={{ width: "25%" }}
          maxLength={4}
          min={drawNoStart}
          max={maxWinning}
        />
        <button
          className="w3-bar w3-button w3-square w3-red"
          style={{ width: "40%", float: "right" }}
          onClick={() =>
            getListWinning(
              document.getElementById("drawNoStart").value,
              document.getElementById("drawNoEnd").value
            )
          }
        >
          당첨번호 조회
        </button>
      </div>
      <div className="w3-panel w3-light-gray w3-padding">
        <InfiniteScroll
          dataLength={listWinning.length}
          next={fetchMoreData}
          hasMore={true}
        >
          <table className="w3-table-all w3-hoverable">
            <thead>
              <tr className="w3-green">
                <th className="w3-center" style={{ width: "20%" }}>
                  회차
                </th>
                <th className="w3-center">당첨번호</th>
              </tr>
            </thead>
            <tbody>
              {listWinning &&
                listWinning.map((item, idx) => {
                  return (
                    <tr key={"tr-" && idx}>
                      <td className="w3-center">
                        {String(item.title).replace("회", "")}
                      </td>
                      <td className="w3-center">
                        {parser(item.no)} + {parser(item.no_bonus)}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </Fragment>
  );
}

export default LottoWinning;
