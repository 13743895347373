import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

/* common */
import * as Helper from "../common/Helper.js";

/* page */
import BlogList from "./BlogList";
import BlogThumb from "./BlogThumb";
import BlogView from "./BlogView";

/* css */
import "../../styles/blog.css";
function Blog({ match, history, location }) {
  const pageId = Helper.getPageId(match.url);
  const category = match.params.category;
  const categoryNo = Helper.getBlogCategoryNo(pageId, category);
  const logNo = match.params.log_no;

  useEffect( () => {
    const contents = {
      Action: "open",
      PathName: location.pathname
    };
    Helper.setLog("blog", contents); //page_id, contents
  }, []);

  const [pageType, setPageType] = useState("list");
  const changePage = (param) => {
    setPageType(param);
  };

  const SubMenuComponent = () => {
    if (pageId === "blog") {
      return (
        <div
          className="w3-panel"
          style={{ marginTop: "0px", marginBottom: "0px" }}
        >
          <NavLink to={"/blog"}>
            <button className="w3-bar-item w3-button w3-hover-none">
              <span
                style={{
                  fontWeight: Helper.isEmpty(category) ? "bold" : "normal",
                }}
              >
                +total
              </span>
            </button>
          </NavLink>

          <NavLink to={"/blog/review"}>
            <button className="w3-bar-item w3-button w3-hover-none">
              <span
                style={{
                  fontWeight: category === "review" ? "bold" : "normal",
                }}
              >
                +review
              </span>
            </button>
          </NavLink>

          <NavLink to={"/blog/etc"}>
            <button className="w3-bar-item w3-button w3-hover-none">
              <span
                style={{
                  fontWeight: category === "etc" ? "bold" : "normal",
                }}
              >
                +etc
              </span>
            </button>
          </NavLink>
        </div>
      );
    } else if (pageId === "coding") {
      return (
        <div className="w3-panel">
          <NavLink to={"/coding"}>
            <button className="w3-bar-item w3-button w3-hover-none">
              <span
                style={{
                  fontWeight: Helper.isEmpty(category) ? "bold" : "normal",
                }}
              >
                +total
              </span>
            </button>
          </NavLink>
          <NavLink to={"/coding/server"}>
            <button className="w3-bar-item w3-button w3-hover-none">
              <span
                style={{
                  fontWeight: category === "server" ? "bold" : "normal",
                }}
              >
                +server
              </span>
            </button>
          </NavLink>
          <NavLink to={"/coding/database"}>
            <button className="w3-bar-item w3-button w3-hover-none">
              <span
                style={{
                  fontWeight: category === "database" ? "bold" : "normal",
                }}
              >
                +database
              </span>
            </button>
          </NavLink>
          <NavLink to={"/coding/code"}>
            <button className="w3-bar-item w3-button w3-hover-none">
              <span
                style={{
                  fontWeight: category === "code" ? "bold" : "normal",
                }}
              >
                +code
              </span>
            </button>
          </NavLink>
          <NavLink to={"/coding/error"}>
            <button className="w3-bar-item w3-button w3-hover-none">
              <span
                style={{
                  fontWeight: category === "error" ? "bold" : "normal",
                }}
              >
                +error
              </span>
            </button>
          </NavLink>
          <NavLink to={"/coding/etc"}>
            <button className="w3-bar-item w3-button w3-hover-none">
              <span
                style={{
                  fontWeight: category === "etc" ? "bold" : "normal",
                }}
              >
                +etc
              </span>
            </button>
          </NavLink>
        </div>
      );
    } else {
      return null;
    }
  };
  const RenderComponent = () => {
    let pageType = "list";
    if (pageId === "blog") {
      //3단계 메뉴
      if (!Helper.isEmpty(logNo)) {
        pageType = "view";
      }
    } else if (pageId === "photo") {
      //2단계 메뉴 (갤러리)
      if (!Helper.isEmpty(logNo)) {
        pageType = "view";
      } else {
        pageType = "thumb";
      }
    } else if (pageId === "portfolio") {
      //2단계 메뉴
      if (!Helper.isEmpty(logNo)) {
        pageType = "view";
      }
    } else if (pageId === "coding") {
      //3단계 메뉴
      if (!Helper.isEmpty(logNo)) {
        pageType = "view";
      }
    }
    //console.log("[web] pageType", pageType);

    switch (pageType) {
      case "view":
        return (
          <BlogView
            pageId={pageId}
            history={history}
            location={location}
            logNo={logNo}
          />
        );
      case "thumb":
        return (
          <BlogThumb
            pageId={pageId}
            history={history}
            location={location}
            categoryNo={categoryNo}
          />
        );
      case "list":
        return (
          <BlogList
            pageId={pageId}
            history={history}
            location={location}
            categoryNo={categoryNo}
          />
        );
      default:
        return null;
    }
  };
  const BlogComponent = () => {
    return (
      <>
        {Helper.isEmpty(logNo) ? SubMenuComponent() : null}
        {RenderComponent()}
      </>
    );
  };

  return <BlogComponent />;
}
export default Blog;
