import React from "react";

const LoginFindPwd = () => {
  return (
    <div className="w3-container w3-border">
      비밀번호 찾기
    </div>
  );
};

export default LoginFindPwd;
