import React, { useState, useEffect } from "react";

/* module */
import axios from "axios";
import Dotdotdot from "react-dotdotdot";
import InfiniteScroll from "react-infinite-scroll-component";

/* common */
import * as Helper from "../common/Helper.js";

const BlogList = (props) => {
  const categoryNo = props.categoryNo;
  const countPerPage = 15; //한페이지에 보여질 갯수
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getPostCount();
  }, []);

  const [postCount, setPostCount] = useState(0);
  const getPostCount = async () => {
    if (categoryNo === 0) return;

    try {
      let url = "/api/blog/getPostCount?categoryNo=" + categoryNo;
      const { data } = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });

      if (data) {
        if (data.statusCode === 200) {
          //console.log("axios success");
          setPostCount(parseInt(data.retData));
        } else {
          //console.log("axios error");
        }
      }
    } catch (e) {
      console.log("error", e);
    }

    getPostList(1, countPerPage); //currentPage, countPerPage
  };

  const [postList, setPostList] = useState([]);
  const getPostList = async (curPage, cntPerPage) => {
    setCurrentPage(curPage);
    try {
      let url =
        "/api/blog/getPostList?categoryNo=" +
        categoryNo +
        "&currentPage=" +
        curPage +
        "&countPerPage=" +
        cntPerPage;
      const { data } = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });

      if (data) {
        if (data.statusCode === 200) {
          //console.log("axios success");
          setPostList(postList.concat(data.retData));
        } else {
          //console.log("axios error");
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const fetchMoreData = () => {
    // a fake async api call like which sends
    if (postList.length < postCount) {
      setTimeout(() => {
        //데이터 불러오는 메세지 출력하기 위해 지연 효과를 줌
        getPostList(currentPage + 1, countPerPage);
      }, 150);
    }
  };

  const goView = (contents, pageId, logNo) => {
    contents.Action = "view";
    contents.PathName = props.location.pathname;
    Helper.setLog(pageId, contents); //page_id, contents

    Helper.goPost(logNo);
  };

  return (
    <div className="w3-container" style={{ paddingBottom: "70px" }}>
      <InfiniteScroll
        dataLength={postList.length}
        next={fetchMoreData}
        hasMore={true}
        loader={
          postList.length < postCount ? (
            <h4 className="w3-center">데이터를 불러옵니다.</h4>
          ) : (
            ""
          )
        }
      >
        {postList &&
          postList.map((item, idx) => {
            item.title = item.title.replace("&lt;", "<");
            item.title = item.title.replace("&rt;", ">");
            return (
              <div
                key={"blog_box_" + (postCount - idx)}
                className={
                  "w3-panel w3-cell-row w3-border-top w3-border-bottom"
                }
                style={{ padding: "0px" }}
              >
                <div
                  key={"blog_no_" + (postCount - idx)}
                  className="w3-left w3-cell w3-margin-top w3-margin-bottom link-cursor"
                  onClick={() =>
                    goView(
                      {
                        Title: decodeURIComponent(
                          String(item.title).replace(/\+/g, " ")
                        ),
                        PostNo: item.logNo,
                        CategoryNo: categoryNo,
                      },
                      props.pageId,
                      String(item.logNo)
                    )
                  }
                >
                  <span className="w3-tag w3-blue">{postCount - idx}</span>
                </div>
                <div
                  key={"blog_title_" + (postCount - idx)}
                  className="w3-left w3-cell w3-margin-top w3-margin-bottom link-cursor"
                  style={{ width: "85%" }}
                  onClick={() =>
                    goView(
                      {
                        Title: decodeURIComponent(
                          String(item.title).replace(/\+/g, " ")
                        ),
                        PostNo: item.logNo,
                        CategoryNo: categoryNo,
                      },
                      props.pageId,
                      String(item.logNo)
                    )
                  }
                >
                  <Dotdotdot clamp={1}>
                    &nbsp;
                    {decodeURIComponent(String(item.title).replace(/\+/g, " "))}
                  </Dotdotdot>
                </div>
              </div>
            );
          })}
      </InfiniteScroll>
    </div>
  );
};

export default BlogList;
