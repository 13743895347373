import React, { useState, useEffect, Fragment } from "react";

/* module */
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import DayPickerInput from "react-day-picker/DayPickerInput";
import * as KorailCommon from "./KorailCommon.js";

/* common */
import * as Helper from "../common/Helper.js";

/* code data */
import KorailStation from "../../jsonData/KorailStationCode.json";

/* css */
import "react-day-picker/lib/style.css";

const KorailSearch = (props) => {
  String.prototype.replaceAll = function (org, dest) {
    return this.split(org).join(dest);
  };

  const numOfRows = 200;

  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getToday(); //현재일자 및 시간
  }, []);

  const [DayPickerDate, setDayPickerDate] = useState("");
  const [today, setToday] = useState({});
  const [timeList, setTimeList] = useState([]);
  const getToday = async () => {
    const t = await axios.get("/api/common/getToday");
    if (!Helper.isEmpty(t)) {
      setToday(t.data);
      setDayPickerDate(t.data.today);

      let arr = [];
      let idx = 0;
      for (let i = parseInt(t.data.hour); i <= 23; i++) {
        arr.push({
          value: Helper.lpad(i, 2, "0"),
          label: Helper.lpad(i, 2, "0") + "시 이후",
        });

        if (idx === 0) {
          setArrTimeSelected({
            value: Helper.lpad(i, 2, "0"),
            label: Helper.lpad(i, 2, "0") + "시 이후",
          });
        }
        idx++;
      }
      setTimeList(arr);

      getCode(t.data.today_nodash); //기차역 리스트
    }
  };

  const [stationList, setStationList] = useState([]);
  const getCode = async (t) => {
    // if (KorailStation.today !== t) {
    //   const station = await axios.get("/api/korail/getStationCode?today=" + t);
    //   setStationList(station.data.retData);
    // } else {
    setStationList(KorailStation.data);
    // }

    //기본값 설정
    setDepPlaceIdSelected({
      citycode: 11,
      cityname: "서울특별시",
      label: "서울",
      nodeid: "NAT010000",
      nodename: "서울",
      value: "NAT010000",
    });

    setArrPlaceIdSelected({
      citycode: 21,
      cityname: "부산광역시",
      label: "부산",
      nodeid: "NAT014445",
      nodename: "부산",
      value: "NAT014445",
    });
  };

  const getTrainNo = (trainNo) => {
    let runDate = String(DayPickerDate).replaceAll("-", "");
    props.history.push(
      "/" + props.pageId + "/view?train_no=" + trainNo + "&run_date=" + runDate
    );
  };

  const [trainList, setTrainList] = useState([]);
  const getTrainList = async (p, n) => {
    if (
      Helper.isEmpty(depPlaceIdSelected.value) ||
      Helper.isEmpty(arrPlaceIdSelected.value)
    ) {
      alert("출발/도착역을 선택하세요.");
      return;
    }
    setPageNo(p);

    try {
      let time = today.today_nodash;
      let url =
        "/api/korail/getTrainList?pageNo=" +
        p +
        "&numOfRows=" +
        n +
        "&depPlaceId=" +
        depPlaceIdSelected.value +
        "&arrPlaceId=" +
        arrPlaceIdSelected.value +
        "&depPlandTime=" + String(DayPickerDate).replaceAll("-", "");
      //"&depPlandTime=" +
      //time;
      // "&trainGradeCode=" +
      // n;

      const { data } = await axios.get(url);
      let tmpData = [];
      if (data) {
        if (data.statusCode === 200 && !Helper.isEmpty(data.retData)) {
          tmpData = data.retData;
          tmpData = tmpData.filter((item) => {
            return (
              parseInt(item.depplandtime) >=
              parseInt(String(DayPickerDate).replaceAll("-", "") + arrTimeSelected.value + "0000")
            );
          });
          let resultData = tmpData.sort(function (a, b) {
            return a.depplandtime < b.depplandtime
              ? -1
              : a.depplandtime > b.depplandtime
              ? 1
              : 0;
          });

          if (p === 1) {
            setTrainList(resultData);
          } else {
            setTrainList(trainList.concat(resultData));
          }

          resultData.map((obj) => {
            axios
              .post("/api/korail/getTrainDly", {
                train_no: obj.trainno,
                dt: String(DayPickerDate).replaceAll("-", ""),
              })
              .then((data) => {
                if (
                  data &&
                  data.data &&
                  data.data.retData &&
                  data.data.retData === "DELAY"
                ) {
                  const btn = document.querySelector("#train_" + obj.trainno);
                  if (btn) {
                    btn.classList.add("w3-text-red");
                  }
                }
              });
          });

          setTotalCount(data.totalCount);
        } else {
          setTrainList([]);
          //console.log("axios error");
        }
      } else {
        setTrainList([]);
        setTotalCount(0);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const setTime = (e) => {
    setDayPickerDate(moment(e).format("YYYY-MM-DD"));
    console.log("setTime", moment(e).format("YYYY-MM-DD"));
    let hour = 0;
    if (moment(e).format("YYYY-MM-DD") === today.today) {
      //선택한 날짜와 오늘날짜가 같을 경우에는 현재시간부터 24시까지만 출력
      hour = today.hour;
    }
    let arr = [];
    let idx = 0;
    for (let i = parseInt(hour); i <= 24; i++) {
      arr.push({
        value: Helper.lpad(i, 2, "0"),
        label: Helper.lpad(i, 2, "0") + "시 이후",
      });
      if (idx === 0) {
        setArrTimeSelected({
          value: Helper.lpad(i, 2, "0"),
          label: Helper.lpad(i, 2, "0") + "시 이후",
        });
      }
      idx++;
    }
    setTimeList(arr);
  };

  const [depPlaceIdSelected, setDepPlaceIdSelected] = useState({
    // citycode: 11,
    // cityname: "서울특별시",
    // label: "서울",
    // nodeid: "NAT010000",
    // nodename: "서울",
    // value: "NAT010000",
  }); //기본값 서울
  const depPlaceIdChange = (e) => {
    setDepPlaceIdSelected(e);
  };

  const [arrPlaceIdSelected, setArrPlaceIdSelected] = useState({
    // citycode: 21,
    // cityname: "부산광역시",
    // label: "부산",
    // nodeid: "NAT014445",
    // nodename: "부산",
    // value: "NAT014445",
  }); //기본값 부산
  const arrPlaceIdChange = (e) => {
    setArrPlaceIdSelected(e);
  };

  const [arrTimeSelected, setArrTimeSelected] = useState({});
  const arrTimeChange = (e) => {
    //    console.log(e);
    setArrTimeSelected(e);
  };

  const renderTip = () => {
    if (trainList) {
      return (
        <Fragment>
          <p
            className="w3-left"
            style={{ marginTop: "5px", marginBottom: "0px", textAlign: "left" }}
          >
            <b>※ 지연정보(5분이상)</b>가 있을 경우  (<i className="fas fa-train link-cursor w3-large w3-text-red" />) 표시<br/>
            <b>※ 데이터 : 공공데이터포털</b>
          </p>
        </Fragment>
      );
    }
  };

  return (
    <Fragment>
      <div className="w3-container w3-light-gray w3-padding">
        <div className="w3-cell-row">
          <div
            className="w3-cell"
            style={{ paddingRight: "10px", width: "50%" }}
          >
            <label>출발역</label>
            <Select
              options={stationList}
              placeholder={"출발역"}
              onChange={depPlaceIdChange}
              value={depPlaceIdSelected}
            />
          </div>
          <div className="w3-cell">
            <label>도착역</label>
            <Select
              options={stationList}
              placeholder={"도착역"}
              onChange={arrPlaceIdChange}
              value={arrPlaceIdSelected}
            />
          </div>
        </div>

        <div className="w3-cell-row" style={{ paddingTop: "10px" }}>
          <div
            className=" w3-cell"
            style={{ paddingRight: "10px", width: "50%" }}
          >
            <label>출발일자</label>
            <DayPickerInput
              id="DayPicker_today"
              placeholder="YYYY-MM-DD"
              value={DayPickerDate}
              dayPickerProps={{
                disabledDays: {
                  before: new Date(today.today),
                },
                numberOfMonths: 1,
                locale: "ko",
              }}
              onDayChange={setTime}
              style={{ display: "block" }}
              className={"w3-input"}
            />
          </div>
          <div className="w3-cell" style={{ textAlign: "left" }}>
            <label>출발시간</label>
            <Select
              options={timeList}
              placeholder={"시간대"}
              onChange={arrTimeChange}
              value={arrTimeSelected}
              isSearchable={false}
            />
          </div>
        </div>

        <p>
          <button
            className="w3-button w3-dark-grey"
            style={{ width: "100%" }}
            onClick={() => getTrainList(1, numOfRows)}
          >
            조회
          </button>
        </p>
      </div>
      <div>
        
        <div className="w3-right" style={{ paddingTop: "10px" }} >
          <i className="fas fa-train link-cursor w3-medium" /> 정차역시간 / <i className="far fa-heart link-cursor w3-medium" /> 자주타는기차
        </div>
        <table className="w3-table-all w3-hoverable">
          <thead>
            <tr className="w3-green">
              <th className="w3-center">열차</th>
              <th className="w3-center" style={{ width: "20%" }}>
                출발
              </th>
              <th className="w3-center" style={{ width: "20%" }}>
                도착
              </th>
              <th className="w3-center" style={{ width: "20%" }}>
                정차역
              </th>
              <th className="w3-center" style={{ width: "12%" }}>
                <i className={"far fa-heart"} />
              </th>
            </tr>
          </thead>
          <tbody>
            {trainList &&
              trainList.map((item, idx) => {
                let favId =
                  String(item.depplacename) +
                  "/" +
                  String(item.arrplacename) +
                  "/" +
                  String(item.depplandtime) +
                  "/" +
                  String(item.trainno);

                let depplandtime =
                  String(item.depplandtime).substring(8, 10) +
                  ":" +
                  String(item.depplandtime).substring(10, 12);

                let arrplandtime =
                  String(item.arrplandtime).substring(8, 10) +
                  ":" +
                  String(item.arrplandtime).substring(10, 12);
                return (
                  <tr key={"tr-" && idx}>
                    <td
                      className="w3-center"
                      onClick={() => getTrainNo(item.trainno)}
                    >
                      {item.traingradename} <br /> {item.trainno}
                    </td>
                    <td
                      className="w3-center"
                      onClick={() => getTrainNo(item.trainno)}
                    >
                      {depplandtime} <br /> {item.depplacename}
                    </td>
                    <td
                      className="w3-center"
                      onClick={() => getTrainNo(item.trainno)}
                    >
                      {arrplandtime} <br /> {item.arrplacename}
                    </td>
                    <td className="w3-center">
                      <i
                        id={"train_" + item.trainno}
                        className="fas fa-train link-cursor w3-large"
                        onClick={() => getTrainNo(item.trainno, today)}
                      />
                    </td>
                    <td className="w3-center">
                      <i
                        id={favId}
                        className={
                          KorailCommon.getKorailFavStyle(item) +
                          " w3-large link-cursor"
                        }
                        onClick={() => KorailCommon.setKorailFav(item)}
                      />
                    </td>
                  </tr>
                );
              })}
            {trainList.length === 0 ? (
              <tr>
                <td className="w3-center" colSpan={5}>
                  조회된 데이터가 없습니다.
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
        {renderTip()}
      </div>
    </Fragment>
  );
};

export default KorailSearch;
