import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";

/* module */
import axios from "axios";
import * as KorailCommon from "./KorailCommon.js";

/* common */
import * as Helper from "../common/Helper.js";

const KorailList = (props) => {
  String.prototype.replaceAll = function (org, dest) {
    return this.split(org).join(dest);
  };

  const [today, setToday] = useState();
  const getToday = async () => {
    const t = await axios.get("/api/common/getToday");
    if (!Helper.isEmpty(t)) {
      setToday(t.data);
      getTrainList(t.data);
    }
  };

  useEffect(() => {
    getToday();
  }, []);

  const [isAlert, setIsAlert] = useState(false);
  const [trainList, setTrainList] = useState([]);
  const getTrainList = (t) => {
    setIsAlert(false);

    let arr = [];
    let data = JSON.parse(localStorage.getItem("localKorailFav"));

    if (Helper.isEmpty(data)) {
      return;
    }
    else {
      arr = data.sort(function (a, b) {        
        return String(a.depplandtime).substring(8,14) < String(b.depplandtime).substring(8,14)
          ? -1
          : String(a.depplandtime).substring(8,14) > String(b.depplandtime).substring(8,14)
          ? 1
          : 0;
      });
    }
    setTrainList(arr);

    let chk = false;
    arr.map((obj) => {      

      if(!chk) {
        if(String(obj.arrplandtime).indexOf(t.today.substring(0, 4)) !== 0) {
          chk = true;
          setIsAlert(true);
        }
      }

      axios
        .post("/api/korail/getTrainDly", {
          train_no: obj.trainno,
          dt: t.today_nodash,
        })
        .then((data) => {
          if (
            data &&
            data.data &&
            data.data.retData &&
            data.data.retData === "DELAY"
          ) {
            const btn = document.querySelector("#train_" + obj.trainno);
            if (btn) {
              btn.classList.add("w3-text-red");
            }
          }
        });
    });
  };

  const renderTip = () => {
    if (trainList && trainList.length > 0) {
      return (
        <Fragment>
          <p
            className="w3-left"
            style={{ marginTop: "5px", marginBottom: "0px", textAlign: "left" }}
          >
            <b>※ 지연정보(5분이상)</b>가 있을 경우  (<i className="fas fa-train link-cursor w3-large w3-text-red" />) 표시<br/>
            <b>※ 데이터 : 공공데이터포털</b>
          </p>
        </Fragment>
      );
    }
  };

  return (
    <Fragment>

      <div className="w3-center">
        
        <div className="w3-center w3-yellow" style={{display:(isAlert ? 'block':'none'), width:'100%',height:'55px'}}>
          <p>2021년 1월 5일 부로 배차정보가 변경되었습니다.<br/>아래에서 붉은색 색상의 열차는 재등록하세요.</p>
        </div> 

        <div className="w3-right" >
          <i className="fas fa-train link-cursor w3-medium" /> 정차역시간 / <i className="fas fa-heart link-cursor w3-medium" /> 자주타는기차
        </div>
        <table className="w3-table-all w3-hoverable">
          <thead>
            <tr className="w3-green">
              <th className="w3-center">열차</th>
              <th className="w3-center" style={{ width: "20%" }}>
                출발
              </th>
              <th className="w3-center" style={{ width: "20%" }}>
                도착
              </th>
              <th className="w3-center" style={{ width: "20%" }}>
                정차역
              </th>
              <th className="w3-center" style={{ width: "12%" }}>
                <i className={"fas fa-heart"} />
              </th>
            </tr>
          </thead>
          <tbody>
            {trainList &&
              trainList.map((item, idx) => {
                let favId =
                  String(item.depplacename) +
                  "/" +
                  String(item.arrplacename) +
                  "/" +
                  String(item.depplandtime) +
                  "/" +
                  String(item.trainno);

                let depplandtime =
                  String(item.depplandtime).substring(8, 10) +
                  ":" +
                  String(item.depplandtime).substring(10, 12);

                let arrplandtime =
                  String(item.arrplandtime).substring(8, 10) +
                  ":" +
                  String(item.arrplandtime).substring(10, 12);

                let fontColor = "black";
                if(String(item.arrplandtime).indexOf("20200101") >= 0) {
                  fontColor = 'red';
                }

                return (
                  <tr key={"tr-" && idx}>
                    <td className="w3-center" style={{color:fontColor}}>
                      {item.traingradename} <br /> {item.trainno}
                    </td>
                    <td className="w3-center">
                      {depplandtime} <br /> {item.depplacename}
                    </td>
                    <td className="w3-center">
                      {arrplandtime} <br /> {item.arrplacename}
                    </td>
                    <td className="w3-center">
                      <NavLink
                        to={
                          "/" +
                          props.pageId +
                          "/view?train_no=" +
                          item.trainno +
                          "&run_date=" +
                          today.today_nodash
                        }
                      >
                        <i
                          id={"train_" + item.trainno}
                          className="fas fa-train link-cursor w3-large"
                        />
                      </NavLink>
                    </td>
                    <td className="w3-center">
                      <i
                        id={favId}
                        className={
                          KorailCommon.getKorailFavStyle(item) +
                          " w3-large link-cursor"
                        }
                        onClick={() => KorailCommon.setKorailFav(item)}
                      />
                    </td>
                  </tr>
                );
              })}
            {trainList.length === 0 ? (
              <tr>
                <td className="w3-center" colSpan={5}>
                  <NavLink to={"/" + props.pageId + "/search"}>
                    <span style={{ fontWeight: "bold" }}>
                      '열차 조회' 후 자주타는 열차(기차)를 등록하세요.
                    </span>
                  </NavLink>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
        
        {trainList.length === 0 ? (
          <NavLink to={"/" + props.pageId + "/search"}>
            <img
              src={"/image/korail/guide.png"}
              className={"link-cursor"}
              style={{ maxWidth: "470px", width: "100%", paddingTop: "20px" }}
            />
          </NavLink>
        ) : renderTip()}
      </div>
    </Fragment>
  );
};

export default KorailList;