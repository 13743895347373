import React, { useState, useEffect } from "react";

/* module */
import axios from "axios";
import Dotdotdot from "react-dotdotdot";
import LazyLoad from "react-lazyload";
import Select from "react-select";

/* common */
import * as Helper from "../common/Helper.js";

const AlbumList = (props) => {
  const [arrAlbumGrp, setArrAlbumGrp] = useState([]);
  const [arrAlbumGrpSelected, setArrAlbumGrpSelected] = useState({});
  useEffect(() => {
    getListAlbumGrp();
  }, []);

  const getListAlbumGrp = async () => {
    try {
      let url = "/api/album/getListAlbumGrp";
      const { data } = await axios.post(url, {
        album_key: props.albumKey,
      });

      if (data) {
        if (data.statusCode === 200) {
          let retData = data.retData;

          let arr = [];
          retData.map((item, idx) => {
            arr.push({
              value: item.Code,
              label: item.CodeName,
            });
          });

          setArrAlbumGrp(arr);
          if (arr.length > 0) {
            setArrAlbumGrpSelected(arr[0]);
            getListAlbum(arr[0]);
          }
        } else {
          setArrAlbumGrpSelected([]);
          setAlbumList([]);
          console.log("axios error");
        }
      }
    } catch (e) {
      setArrAlbumGrpSelected([]);
      setAlbumList([]);
      console.log("error", e);
    }
  };

  const [albumList, setAlbumList] = useState([]);
  const getListAlbum = async (selected) => {
    try {
      let url = "/api/album/getListAlbum";
      const { data } = await axios.post(url, {
        album_grp: selected.value,
        album_key: props.albumKey,
      });

      if (data) {
        if (data.statusCode === 200) {
          let retData = data.retData;
          retData = retData.sort(function (a, b) {
            return a.SortSeq > b.SortSeq ? -1 : a.SortSeq < b.SortSeq ? 1 : 0;
          });
          setAlbumList(retData);
        } else {
          setAlbumList([]);
          //console.log("axios error");
        }
      }
    } catch (e) {
      console.log("error", e);
      setAlbumList([]);
    }
  };

  const reAlbumKey = () => {
    //초기화
    localStorage.removeItem("localAlbum");
    props.history.replace(props.location.pathname);
  };

  const arrAlbumGrpChange = (e) => {
    setArrAlbumGrpSelected(e);
    getListAlbum(e);
  };

  const goPhoto = (contents, albumUrl, openId) => {
    contents.Action = "goPopup";
    contents.PathName = "/album/list";
    contents.AlbumGrp = arrAlbumGrpSelected.label;
    contents.AlbumKey = props.albumKey;
    Helper.setLog("album", contents); //page_id, contents

    Helper.goPopup(albumUrl, openId);
  };

  return (
    <div className="w3-container" style={{ paddingBottom: "70px" }}>
      <Select
        id="AlbumGrp"
        placeholder={"앨범 그룹"}
        options={arrAlbumGrp}
        onChange={arrAlbumGrpChange}
        isSearchable={false}
        value={arrAlbumGrpSelected}
      />

      <LazyLoad once={true}>
        {albumList &&
          albumList.map((item, idx) => {
            item.AlbumName = String(item.AlbumName).replaceAll("&lt;", "<");
            item.AlbumName = String(item.AlbumName).replaceAll("&rt;", ">");
            return (
              <div
                key={"blog_box_" + idx}
                className={
                  "w3-panel w3-cell-row w3-border-top w3-border-bottom"
                }
                style={{ padding: "0px" }}
              >
                <div
                  key={"blog_no_" + idx}
                  className="w3-left w3-cell w3-margin-top w3-margin-bottom link-cursor"
                  onClick={() =>
                    goPhoto(
                      { AlbumName: item.AlbumName },
                      item.AlbumUrl,
                      "gphoto"
                    )
                  }
                >
                  <img
                    src={item.AlbumThumb}
                    style={{ width: "64px", height: "64px" }}
                  />
                </div>
                <div
                  key={"blog_title_" + idx}
                  className="w3-left w3-cell w3-margin-top w3-margin-bottom w3-margin-left link-cursor"
                  onClick={() =>
                    goPhoto(
                      { AlbumName: item.AlbumName },
                      item.AlbumUrl,
                      "gphoto"
                    )
                  }
                >
                  <Dotdotdot clamp={1}>
                    &nbsp;
                    {item.AlbumName}
                  </Dotdotdot>
                </div>
              </div>
            );
          })}
      </LazyLoad>
      {albumList.length === 0 ? (
        <div
          className={"w3-panel w3-cell-row w3-border-top w3-border-bottom"}
          style={{ padding: "0px" }}
        >
          <div className="w3-left w3-cell w3-margin-top w3-margin-bottom w3-margin-left link-cursor">
            해당하는 서비스구분/인증키{" "}
            <span style={{ fontWeight: "bold" }}>[{props.albumKey}]</span> 에
            등록된 앨범이 없습니다.
          </div>
          <div className="w3-left w3-cell w3-margin-top w3-margin-bottom w3-margin-left link-cursor">
            <a
              href="#"
              className="w3-bar-item w3-button w3-padding-medium w3-large"
              onClick={() => reAlbumKey()}
            >
              <i
                className="fas fa-redo w3-hover-opacity w3-large"
                style={{ width: "30px", textAlign: "left" }}
              />
              재인증
            </a>
          </div>
        </div>
      ) : (
        <div style={{ marginBottom: "-70px" }}>
          <a
            href="#"
            className="w3-bar-item w3-button w3-padding-medium w3-small"
            onClick={() => reAlbumKey()}
          >
            <i
              className="fas fa-redo w3-hover-opacity w3-small"
              style={{ width: "30px", textAlign: "left" }}
            />
            인증키 재인증
          </a>
        </div>
      )}
    </div>
  );
};

export default AlbumList;
