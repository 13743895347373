import React from "react";
import { Route } from "react-router-dom";
import AppBody from "./AppBody";

export default () => (
  <>
    <Route exact={true} component={() => <AppBody pageId={"test"} version={"v1.0"} />}            path={["/test", "/test/:page_type"]} />

    <Route exact={true} component={() => <AppBody pageId={"home"} version={"v1.0"} />}            path={["/", "/home"]} />
    <Route exact={true} component={() => <AppBody pageId={"blog"} version={"v1.0"} />}            path={["/blog", "/blog/:category", "/blog/:category/:log_no"]} />
    <Route exact={true} component={() => <AppBody pageId={"photo"} version={"v1.0"} />}           path={["/photo", "/photo/:log_no"]} />
    <Route exact={true} component={() => <AppBody pageId={"portfolio"} version={"v1.0"} />}       path={["/portfolio", "/portfolio/:log_no"]} />
    <Route exact={true} component={() => <AppBody pageId={"coding"} version={"v1.0"} />}          path={["/coding", "/coding/:category", "/coding/:category/:log_no"]} />
    <Route exact={true} component={() => <AppBody pageId={"link"} version={"v1.0"} />}            path={["/link", "/link/:page_type"]} />
    <Route exact={true} component={() => <AppBody pageId={"privacy"} version={"v1.0"} />}         path={["/privacy"]} />
    <Route exact={true} component={() => <AppBody pageId={"sitemap"} version={"v1.0"} />}         path={["/sitemap"]} />
    <Route exact={true} component={() => <AppBody pageId={"login"} version={"v1.0"} />}           path={["/login", "/login/:page_type", "/my", "/my/:page_type"]} />
    <Route exact={true} component={() => <AppBody pageId={"logout"} version={"v1.0"} />}          path={["/logout"]} />

    <Route exact={true} component={() => <AppBody pageId={"lotto"} version={"v1.0"} />}           path={["/lotto", "/lotto/:category"]} />
    <Route exact={true} component={() => <AppBody pageId={"lotto_import"} version={"v1.0"} />}    path={["/lotto_import", "/lotto_import/:category"]} />
    <Route exact={true} component={() => <AppBody pageId={"korail"} version={"v1.0"} />}          path={["/korail", "/korail/:page_type"]} />
    <Route exact={true} component={() => <AppBody pageId={"korail_import"} version={"v1.0"} />}   path={["/korail_import", "/korail_import/:page_type"]} />

    <Route exact={true} component={() => <AppBody pageId={"subway"} version={"v1.0"} />}          path={["/subway", "/subway/:page_type"]} />
    <Route exact={true} component={() => <AppBody pageId={"subway_import"} version={"v1.0"} />}   path={["/subway_import", "/subway_import/:page_type"]} />
    <Route exact={true} component={() => <AppBody pageId={"album"} version={"v1.0"} />}           path={["/ccu", "/ccu/:page_type", "/album", "/album/:page_type"]} />
    <Route exact={true} component={() => <AppBody pageId={"album_import"} version={"v1.0"} />}    path={[ "/album_import",
                                                                                                          "/album_import/:page_type",
                                                                                                          "/ccu_import",
                                                                                                          "/ccu_import/:page_type",
                                                                                                        ]} />
    <Route exact={true} component={() => <AppBody pageId={"profit"} version={"v1.0"} />}         path={["/profit", "/profit/:page_type"]}/>
    <Route exact={true} component={() => <AppBody pageId={"finance"} version={"v1.0"} />}         path={["/finance", "/finance/:page_type"]}/>
    <Route exact={true} component={() => <AppBody pageId={"finance_import"} version={"v1.0"} />}  path={["/finance_import", "/finance_import/:page_type"]} />
    <Route exact={true} component={() => <AppBody pageId={"insta"} version={"v1.0"} />}         path={["/insta", "/insta/:page_type"]}/>
    <Route exact={true} component={() => <AppBody pageId={"insta_import"} version={"v1.0"} />}  path={["/insta_import", "/insta_import/:page_type"]} />

    <Route exact={true} component={() => <AppBody pageId={"todo"} version={"v1.0"} />}            path={["/todo", "/todo/:page_type"]} />
    <Route exact={true} component={() => <AppBody pageId={"todo_import"} version={"v1.0"} />}     path={["/todo_import", "/todo_import/:page_type"]} />
    
  </>
);
