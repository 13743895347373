import React, { useEffect, useState, Fragment } from "react";

/* module */
import parser from "html-react-parser";

/* common */
import * as Helper from "../common/Helper.js";
import * as LottoCommon from "./LottoCommon.js";

function LottoCreate(props) {
  // 생성한 lotto 번호
  const [arrLottoNo, setLottoNo] = useState([]);
  const [arrLottoFavNo, setLottoFavNo] = useState([]);

  useEffect(() => {
    window.addEventListener("click", setSelectNo);
  }, []);

  //초기 셋팅 값
  const getLottoNo = () => {
    let str = "";
    for (let i = 1; i <= 45; i++) {
      str +=
        "<button class='w3-button lotto-no' style='width:45px;height:45px;margin-right:5px;margin-bottom:5px;'>" +
        i +
        "</button>";
    }
    return str;
  };

  function setSelectNo(e) {
    if (e.target.nodeName === "BUTTON") {
      const target = String(e.target.className).toString();
      if (target.indexOf("w3-button lotto-no") !== -1) {
        if (target.indexOf("w3-gray") !== -1) {
          e.target.classList.remove("w3-gray");
        } else {
          const btn = document.querySelectorAll("button");
          let nodeCnt = 1;
          btn.forEach((b) => {
            if (String(b.className).indexOf("w3-gray") !== -1) {
              nodeCnt++;
            }
          });
          if (nodeCnt > 5) {
            alert("최대 5개 까지만 선택 가능합니다.");
            return;
          } else {
            e.target.classList.add("w3-gray");
          }
        }

        let selectValue = e.srcElement.innerText;
        let arr = [];
        const btn = document.querySelectorAll(".w3-gray");
        btn.forEach((b) => {
          if (String(b.className).indexOf("w3-gray") !== -1) {
            if (arr.indexOf(selectValue) < 0) {
              arr.push(b.innerText);
            }
          }
        });
        arr = arr.sort(function (a, b) {
          return a - b;
        });
        setLottoFavNo(arr);
      }
    }
  }

  const setLottoCreate = () => {
    let arr = [];
    for (let i = 1; i <= 5; i++) {
      let tmp = numberCreate();
      arr.push(tmp);
    }

    setLottoNo(arr);
    setLottoHist(arr);

    setTimeout(() => {
      Helper.moveScroll(window.pageYOffset + 500);
    }, 50);
  };

  const setLottoHist = (arr) => {
    let lc = JSON.parse(localStorage.getItem("localLottoHist"));
    if (lc && lc.length > 45) {
      lc = lc.slice(0, 45);
    }

    let arrNew = arr;
    if (lc && lc.length > 0) {
      arrNew = arrNew.concat(lc);
    }

    if (arrNew && arrNew.length >= 5) {
      localStorage.setItem("localLottoHist", JSON.stringify(arrNew));
    }

    const UserInfo = Helper.getSession();
    if(Helper.isSession() && UserInfo && UserInfo.LoginId) {
      Helper.setLocalstorage("localLottoHist", UserInfo.LoginId);
    }           
  };

  let num = [];
  function numberCreate() {
    num = [];
    arrLottoFavNo.forEach((no) => {
      num.push(no);
    });
    for (let i = arrLottoFavNo.length; num.length <= 5; i++) {
      let n = parseInt(getRandomArbitrary(1, 45, num));
      if (num.indexOf(n) <= -1) {
        num.push(n);
      }
    }
    num = num.sort(function (a, b) {
      return a - b;
    });
    return num;
  }

  function getRandomArbitrary(min, max, except) {
    let retValue = Math.random() * (max - min) + min;
    if (except.indexOf(retValue) < 0) {
      return retValue;
    } else {
      //재귀
      getRandomArbitrary(1, 45, except);
    }
  }

  //변환
  function getLottoNoConv(arr, i) {
    let str = "";
    let color = "";
    arr.forEach((no) => {
      if (no < 10) {
        color = "ball1";
      } else if (no < 20) {
        color = "ball2";
      } else if (no < 30) {
        color = "ball3";
      } else if (no < 40) {
        color = "ball4";
      } else if (no < 50) {
        color = "ball5";
      }
      str +=
        "<span class='ball_645 mid " +
        color +
        "' style='margin-right:7px;'>" +
        no +
        "</span>";
    });
    return str; //ball_645 sml ball1
  }

  return (
    <Fragment>
      <div className="w3-panel w3-light-gray w3-padding">
        <h5>선호번호를 선택하세요. (최대5개)</h5>
        <div dangerouslySetInnerHTML={{ __html: getLottoNo() }}></div>
      </div>
      <div className="w3-panel w3-padding">
        <button
          className="w3-bar w3-button w3-square w3-red"
          onClick={() => setLottoCreate()}
        >
          번호생성 <i className="fas fa-hand-point-left" />
        </button>
      </div>
      <div>
        <table className="w3-table-all w3-hoverable">
          <thead>
            <tr className="w3-green">
              <th className="w3-center" style={{ width: "15%" }}>
                No
              </th>
              <th className="w3-center">생성 번호</th>
              <th className="w3-center" style={{ width: "15%" }}>
                보관
              </th>
            </tr>
          </thead>
          <tbody>
            {arrLottoNo &&
              arrLottoNo.map((item, idx) => {
                return (
                  <tr key={"tr-" && idx}>
                    <td className="w3-center">{idx + 1}</td>
                    <td className="w3-center">
                      {parser(getLottoNoConv(item))}
                    </td>
                    <td className="w3-center">
                      <i
                        id={String(item)}
                        className={
                          LottoCommon.getLottoFavStyle(item) + " link-cursor"
                        }
                        onClick={() => LottoCommon.setLottoFav(item)}
                      />
                    </td>
                  </tr>
                );
              })}
            {arrLottoNo.length === 0 ? (
              <tr>
                <td className="w3-center" colSpan={3}>
                  번호를 생성하세요.
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}

export default LottoCreate;
