import React from "react";

const LoginFindId = () => {
  return (
    <div className="w3-container w3-border">
      아이디 찾기
    </div>
  );
};

export default LoginFindId;
