import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

/* common */
import * as Helper from "../common/Helper.js";

/* page */
import InstaList from "./InstaList";
import InstaListFav from "./InstaListFav";

/* css */

function Insta({ match, history, location }) {
  const pageId = Helper.getPageId(match.url);
  let pageType = match.params.page_type;

  useEffect( () => {
    const contents = {
      Action: "open",
      PathName: location.pathname
    };
    Helper.setLog("insta", contents); //page_id, contents
  }, []);

  const RenderComponent = () => {
    if (Helper.isEmpty(pageType)) {
      pageType = "listFav";
    }

    switch (pageType) {
      case "list":
        return (
          <InstaList history={history} location={location} pageId={pageId} />
        );
      
      case "listFav":
        return (
          <InstaListFav history={history} location={location} pageId={pageId} />
        );

      default:
        return null;
    }
  };
  const InstaComponent = () => {
    return (
      <div className="w3-display-top" style={{ paddingBottom: "70px" }}>
        <div
          className="w3-bar w3-green"
          style={{ paddingBottom: "5px", paddingTop: "5px" }}
        >
          <NavLink to={"/" + pageId + "/listFav"}>
            <button className="w3-bar-item w3-button"><b>모아보기</b></button>
          </NavLink>

          <NavLink to={"/" + pageId + "/list"}>
            <button className="w3-bar-item w3-button"><b>인스타 목록</b></button>
          </NavLink>
        </div>
        <div className="w3-panel">{RenderComponent()}</div>
      </div>
    );
  };

  return <InstaComponent />;
}
export default Insta;
