import React, { useState, useEffect, Fragment } from "react";

/* module */
import axios from "axios";
import * as ProfitCommon from "./ProfitCommon.js";

const ProfitSearch = (props) => {
  useEffect(() => {
    // 스크롤 시 IME 닫기
    function onScroll() {
      const searchWord = document.querySelector('#searchWord');      
      searchWord && searchWord.blur();
    }
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });
  
  useEffect(() => {
    
  }, []);

  const handleKeyEvent = (e) => {
    autoCompl(document.querySelector("#searchWord").value);
  }

  const [searchList, setSearchList] = useState([]);
  const autoCompl = (value) => {
    axios
      .get("/api/finance/getFinanceCd?search=" + encodeURI(value))
      .then((data) => {
        if ( data && data.data && data.data.retData) {          
          let retData = data.data.retData;
          retData = retData.sort(function (a, b) {
            return a.cd < b.cd
              ? -1
              : a.cd > b.cd
              ? 1
              : 0;
          });

          setSearchList(retData);
        }
        else {
          setSearchList([]);
        }
      })
      .catch( (e) => {
        console.log('error', e);
      });
  }

  const renderTip = () => {
    return (
      <Fragment>
        <p
          className="w3-left"
          style={{ marginTop: "5px", marginBottom: "0px" }}
        >
          <b>※ 데이터 : 네이버 증권</b>
        </p>
      </Fragment>
    );
  };


  return (
    <Fragment>
      <div className="w3-container w3-light-gray w3-padding">
        <div className="w3-cell-row">
          <div className="w3-cell" style={{ paddingRight: "10px", width: "100%" }} >
            <input type="text" id={"searchWord"} className={"w3-input w3-round"} autoComplete={"off"} placeholder={"종목명을 입력하세요."} onKeyUp={handleKeyEvent}  />
          </div>
        </div>
      </div>
      <div>
        <div className="w3-right" style={{ paddingTop: "10px" }} >
          <i className="far fa-heart link-cursor w3-medium" /> 보유종목 / <i className="far fa-star link-cursor w3-medium" /> 관심종목
        </div>
        <table className="w3-table-all w3-hoverable">
          <thead>
            <tr className="w3-green">
              <th className="w3-center" style={{ width: "25%" }}>종목코드</th>
              <th className="w3-center">종목명</th>
              <th className="w3-center" style={{ width: "25%" }}><i className="far fa-heart link-cursor w3-large" /> / <i className="far fa-star link-cursor w3-large" /></th>
            </tr>
          </thead>
          <tbody>
            {searchList &&
              searchList.map((item, idx) => {
                let favId = String(item.cd);

                return (
                  <tr key={"tr-" && idx}>
                    <td className="w3-center">
                      {item.cd}
                    </td>
                    <td className="w3-left">
                      {item.nm}
                    </td>
                    <td className="w3-center">
                      <i
                        id={'prch_' + favId}
                        className={
                          ProfitCommon.getProfitPrchStyle(item) +
                          " w3-large link-cursor"
                        }
                        onClick={() => ProfitCommon.setProfitPrch({...item, prch_qty:0, prch_price:0})}
                      />
                      {' / '}
                      <i
                        id={'fav_' + favId}
                        className={
                          ProfitCommon.getProfitFavStyle(item) +
                          " w3-large link-cursor"
                        }
                        onClick={() => ProfitCommon.setProfitFav(item)}
                      />
                    </td>
                  </tr>
                );
              })}
            {searchList.length === 0 ? (
              <tr>
                <td className="w3-center" colSpan={3}>
                  조회된 데이터가 없습니다.
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>     
        {renderTip()}
      </div>
      
    </Fragment>
  );
};

export default ProfitSearch;
