import React from "react";

/* module */
import axios from "axios";

/* common */
import * as Helper from "../common/Helper.js";

const LoginPassword = () => {
  return (
    <div className="w3-container w3-border">
      비밀번호 변경
    </div>
  );
};

export default LoginPassword;
