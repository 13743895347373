import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import queryString from "query-string";

/* common */
import * as Helper from "../common/Helper.js";

/* page */
import KorailList from "./KorailList";
import KorailSearch from "./KorailSearch";
import KorailView from "./KorailView";

/* css */

function Korail({ match, history, location }) {
  const pageId = Helper.getPageId(match.url);
  let pageType = match.params.page_type;

  const query = queryString.parse(location.search);
  const trainNo = query.train_no;
  const runDate = query.run_date;

  useEffect( () => {
    const contents = {
      Action: "open",
      PathName: location.pathname
    };
    Helper.setLog("korail", contents); //page_id, contents
  }, []);

  const RenderComponent = () => {
    if (Helper.isEmpty(pageType)) {
      pageType = "list";
    }
    if (!Helper.isEmpty(trainNo)) {
      pageType = "view";
    }

    switch (pageType) {
      case "view":
        return (
          <KorailView
            history={history}
            location={location}
            pageId={pageId}
            trainNo={trainNo}
            runDate={runDate}
          />
        );
      case "list":
        return (
          <KorailList history={history} location={location} pageId={pageId} />
        );
      case "search":
        return (
          <KorailSearch history={history} location={location} pageId={pageId} />
        );
      default:
        return null;
    }
  };
  const KorailComponent = () => {
    return (
      <div className="w3-display-top" style={{ paddingBottom: "70px" }}>
        <div
          className="w3-bar w3-green"
          style={{ paddingBottom: "5px", paddingTop: "5px" }}
        >
          <NavLink to={"/" + pageId + "/list"}>
            <button className="w3-bar-item w3-button"><b>자주타는열차</b></button>
          </NavLink>

          <NavLink to={"/" + pageId + "/search"}>
            <button className="w3-bar-item w3-button"><b>열차조회</b></button>
          </NavLink>

          {/* <NavLink to={"/" + pageId + "/help"}>
            <button className="w3-bar-item w3-button">사용방법</button>
          </NavLink> */}
        </div>
        <div className="w3-panel">{RenderComponent()}</div>
      </div>
    );
  };

  return <KorailComponent />;
}
export default Korail;
