import React, { useState, useEffect } from "react";

/* module */
import axios from "axios";

/* common */
import * as Helper from "../common/Helper.js";

const LinkKey = (props) => {
  const keySubmit = () => {
    const albumKey = document.querySelector("#LinkKey");
    if (albumKey && Helper.isEmpty(albumKey.value)) {
      alert("[인증키] 를 입력하세요.");
      albumKey.focus();
      return;
    }

    if (Helper.isEmpty(albumKey)) {
      alert("undefined error");
      return;
    }

    // 전송
    submit(albumKey.value);
  };

  const submit = async (albumKey) => {
    //초기화
    localStorage.removeItem("localLink");

    const retData = await axios.post("/api/album/access", {
      album_key: albumKey,
    });

    if (!Helper.isEmpty(retData.data)) {
      const data = retData.data;

      if (data.statusCode === 200) {
        if (data.retData === "AccessOK") {
          localStorage.setItem("localLink", albumKey);

          Helper.setLog("link", {
            Action: "access",
            LinkKey: albumKey,
            Status: String(data.statusCode),
            retData: String(data.retData),
          });

          props.history.push("/link/chinese");
        } else {
          alert("F / " + data.retMsg);
        }
      } else {
        alert("F / " + data.retMsg);
      }
    }
  };

  function handleKeyEvent(e) {
    switch (e.type) {
      case "keydown":
        if (e.keyCode === 13) {
          keySubmit();
        }
        break;
    }
  }

  return (
    <div className="w3-container w3-border">
      <p>
        <label>
          <i className="fas fa-key w3-xlarge"></i> 인증키를 입력하세요.
        </label>
      </p>
      <input
        className="w3-input w3-border"
        type="number"
        placeholder="인증키"
        id="LinkKey"
        required="required"
        maxLength={8}
        onKeyDown={handleKeyEvent}
        onKeyUp={handleKeyEvent}
        style={{ imeMode: "disabled" }}
      />
      <p>
        <button
          className="w3-button w3-block w3-green w3-left-align"
          onClick={() => keySubmit()}
        >
          <i className="fa fa-search w3-margin-right"></i> 제출
        </button>
      </p>
    </div>
  );
};

export default LinkKey;
