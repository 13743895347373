import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

/* common */
import * as Helper from "../common/Helper.js";

/* page */
import LottoCreate from "./LottoCreate";
import LottoWinning from "./LottoWinning";
import LottoHist from "./LottoHist";
import LottoFav from "./LottoFav";

/* css */
import "../../styles/lotto.css";

function Lotto({ match, history, location }) {
  const pageId = Helper.getPageId(match.url);
  const category = match.params.category;

  useEffect( () => {
    const contents = {
      Action: "open",
      PathName: location.pathname
    };
    Helper.setLog("lotto", contents); //page_id, contents
  }, []);

  const RenderComponent = () => {
    switch (category) {
      case "winning":
        return <LottoWinning history={history} location={location} />;
      case "hist":
        return <LottoHist history={history} location={location} />;
      case "fav":
        return <LottoFav history={history} location={location} />;
      case undefined:
      case "create":
      default:
        return <LottoCreate history={history} location={location} />;
    }
  };
  const LottoComponent = () => {
    return (
      <div className="w3-display-top" style={{ paddingBottom: "70px" }}>
        <div
          className="w3-bar w3-green"
          style={{ paddingBottom: "5px", paddingTop: "5px" }}
        >
          <NavLink to={"/" + pageId + "/create"}>
            <button className="w3-bar-item w3-button">번호생성</button>
          </NavLink>

          <NavLink to={"/" + pageId + "/hist"}>
            <button className="w3-bar-item w3-button">생성이력</button>
          </NavLink>

          <NavLink to={"/" + pageId + "/fav"}>
            <button className="w3-bar-item w3-button">보관함</button>
          </NavLink>

          <NavLink to={"/" + pageId + "/winning"}>
            <button className="w3-bar-item w3-button">당첨번호</button>
          </NavLink>
        </div>
        <div className="w3-panel">{RenderComponent()}</div>
      </div>
    );
  };

  return <LottoComponent />;
}
export default Lotto;
