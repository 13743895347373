/* common */
import * as Helper from "../common/Helper.js";

export const setFinanceFav = (arr) => {
  //fas ♥  , far ♡
  let lc = JSON.parse(localStorage.getItem("localFinanceFav"));

  let favId = 'fav_' + String(arr.cd);

  if (document.getElementById(favId).className.indexOf("fas fa-star") >= 0) {
    //배열에서 arr을 찾아야 함
    //배열에서 해당 번호 삭제
    //재등록
    if (lc && lc.length >= 1) {
      //localStorage.removeItem("localFinanceFav");

      //동일차량이 있는지 체크
      let temp = [];
      lc &&
        lc.map((item) => {
          let chk1 = String(item.cd);
            
          let chk2 = String(arr.cd);
          if (chk1.indexOf(chk2) === -1) {
            temp.push(item);
          }
        });

      temp && localStorage.setItem("localFinanceFav", JSON.stringify(temp));
      document.getElementById(favId).className = "far fa-star link-cursor";
    }
  } else {
    lc &&
      lc.map((item) => {
        let chk1 = String(item.cd);
        let chk2 = String(arr.cd);

        if (chk1 === chk2) {
          alert("동일한 종목이 이미 [관심종목]에 있습니다.");
          return;
        }
      });

    let arrNew = [arr];
    if (lc && lc.length > 0) {
      arrNew = arrNew.concat(lc);
    }

    if (arrNew && arrNew.length >= 1) {
      localStorage.setItem("localFinanceFav", JSON.stringify(arrNew));
      document.getElementById(favId).className = "fas fa-star link-cursor";
    }
  }

  const UserInfo = Helper.getSession();
  if(Helper.isSession() && UserInfo && UserInfo.LoginId) {
    Helper.setLocalstorage("localFinanceFav", UserInfo.LoginId);
  }    
};

export const getFinanceFavStyle = (arr) => {
  let retValue = "far fa-star";

  const lc = JSON.parse(localStorage.getItem("localFinanceFav"));
  let temp = [];
  lc &&
    lc.map((item) => {
      temp.push(String(item.cd));
    });
  if (
    temp.includes(String(arr.cd))
  ) {
    retValue = "	fas fa-star";
  }
  return retValue;
};

export const setFinancePrch = (arr) => {
  //fas ♥  , far ♡
  let lc = JSON.parse(localStorage.getItem("localFinancePrch"));

  let favId = 'prch_' + String(arr.cd);

  if (document.getElementById(favId).className.indexOf("fas fa-heart") >= 0) {
    //배열에서 arr을 찾아야 함
    //배열에서 해당 번호 삭제
    //재등록
    if (lc && lc.length >= 1) {
      //localStorage.removeItem("localFinanceFav");

      //동일차량이 있는지 체크
      let temp = [];
      lc &&
        lc.map((item) => {
          let chk1 = String(item.cd);
            
          let chk2 = String(arr.cd);
          if (chk1.indexOf(chk2) === -1) {
            temp.push(item);
          }
        });

      temp && localStorage.setItem("localFinancePrch", JSON.stringify(temp));
      document.getElementById(favId).className = "far fa-heart link-cursor";
    }
  } else {
    lc &&
      lc.map((item) => {
        let chk1 = String(item.cd);
        let chk2 = String(arr.cd);

        if (chk1 === chk2) {
          alert("동일한 종목이 이미 [보유종목]에 있습니다.");
          return;
        }
      });

    let arrNew = [arr];
    if (lc && lc.length > 0) {
      arrNew = arrNew.concat(lc);
    }

    if (arrNew && arrNew.length >= 1) {
      localStorage.setItem("localFinancePrch", JSON.stringify(arrNew));
      document.getElementById(favId).className = "fas fa-heart link-cursor";
    }
  }

  const UserInfo = Helper.getSession();
  if(Helper.isSession() && UserInfo && UserInfo.LoginId) {
    Helper.setLocalstorage("localFinancePrch", UserInfo.LoginId);
  }
};

export const getFinancePrchStyle = (arr) => {
  let retValue = "far fa-heart";

  const lc = JSON.parse(localStorage.getItem("localFinancePrch"));
  let temp = [];
  lc &&
    lc.map((item) => {
      temp.push(String(item.cd));
    });
  if (
    temp.includes(String(arr.cd))
  ) {
    retValue = "fas fa-heart";
  }
  return retValue;
};


export const save = (obj) => {
    let lc = JSON.parse(localStorage.getItem("localFinancePrch"));
    localStorage.removeItem("localFinancePrch");

    //배열에서 arr을 찾아야 함
    //배열에서 해당 번호 삭제
    //재등록
    if (lc && lc.length >= 1) {

      //동일 값이 아닌 경우만 배열에 담아 둔다.
      let temp = [];
      lc && lc.map((item) => {
        let chk1 = String(item.cd);
        let chk2 = String(obj.cd);
        if (chk1.indexOf(chk2) === -1) {
          temp.push(item);
        }
      });
      let prch_qty = document.querySelector("#prch_qty_" + obj.cd).value;
      let prch_price = document.querySelector("#prch_price_" + obj.cd).value;

      temp.push({
        cd : obj.cd,
        nm : obj.nm,
        type : obj.type,
        prch_qty : prch_qty,
        prch_price : prch_price,
      });
      localStorage.setItem("localFinancePrch", JSON.stringify(temp));
    }

    const UserInfo = Helper.getSession();
    if(Helper.isSession() && UserInfo && UserInfo.LoginId) {
      Helper.setLocalstorage("localFinancePrch", UserInfo.LoginId);
    }
  }