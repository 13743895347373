/* common */
import * as NativeIF from "../common/NativeIF.js";

/* module */
const axios = require("axios");
const moment = require("moment");


let today = moment(new Date());
String.prototype.replaceAll = function (org, dest) {
  return this.split(org).join(dest);
};

/**
 * 좌측문자열채우기
 * 예) Helper.lpad("문자", 5, "0")
 * @params
 *  - str : 원 문자열
 *  - padLen : 최대 채우고자 하는 길이
 *  - padStr : 채우고자하는 문자(char)
 */
export const lpad = (str, padLen, padStr) => {
  if (padStr.length > padLen) {
    console.log("오류 : 채우고자 하는 문자열이 요청 길이보다 큽니다");
    return str;
  }
  str += ""; // 문자로
  padStr += ""; // 문자로
  while (str.length < padLen) str = padStr + str;
  str = str.length >= padLen ? str.substring(0, padLen) : str;
  return str;
};

/**
 * 우측문자열채우기
 * 예) Helper.rpad("문자", 5, "0")
 * @params
 *  - str : 원 문자열
 *  - padLen : 최대 채우고자 하는 길이
 *  - padStr : 채우고자하는 문자(char)
 */
export const rpad = (str, padLen, padStr) => {
  if (padStr.length > padLen) {
    console.log("오류 : 채우고자 하는 문자열이 요청 길이보다 큽니다");
    return str + "";
  }
  str += ""; // 문자로
  padStr += ""; // 문자로
  while (str.length < padLen) str += padStr;
  str = str.length >= padLen ? str.substring(0, padLen) : str;
  return str;
};

/**
 * 오늘 날짜 반환
 * 예) Helper.getToday()
 * @params
 *
 */
export const getToday = async () => {
  const t = await axios.get("/api/common/getToday");
  return t.data;
};

/**
 * 현재 시간 반환
 * 예) Helper.getNow()
 * @params
 *  - type : "HH"
 */
export const getNow = (type) => {
  today = today.add(9, "hours"); //서울 시간
  type = "HH";
  console.log("today", today.format(type));
  return today.format(type);
};

/**
 * 팝업(새창)
 * 예) Helper.goPopup("https://jochanyang.ddns.net", "id")
 * @params
 *  - window.open 방식임
 *  - url : 이동하고자 하는 주소(url)
 *  - id : 창의 id
 */
export const goPopup = (url, id) => {
  window.open(url, id);
};

/**
 * 블로그 글 내용 보기
 * 예) Helper.goPost(false, "blog", 1234)
 * @params
 *  - naverOpen : true일 경우 naver창으로 이동, false일 때에만 현재창에서 보여짐
 *  - pageId
 *  - logNo : 게시글 번호
 */
export const goPost = (logNo) => {
  let naverUrl = "https://blog.naver.com/jochanyang/" + logNo;
  if (isMobile()) {
    naverUrl = "https://m.blog.naver.com/jochanyang/" + logNo; //모바일용
  }
  window.open(naverUrl, "naver_blog");
};

/**
 * 모바일 기기 여부 확인
 * 예) Helper.isMobile()
 * @params
 *  - 없음
 */
export const isMobile = () => {
  var mobile = /iphone|ipad|ipod|android/i.test(
    navigator.userAgent.toLowerCase()
  );
  if (mobile) {
    return true;
  } else {
    return false;
  }
};

/**
 * 모바일의 os값 return
 * 예) Helper.getOS()
 * @params
 *  - 없음
 */
export const getOS = () => {
  var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
  if (varUA.indexOf("android") > -1) {
    //안드로이드
    return "android";
  } else if (
    varUA.indexOf("iphone") > -1 ||
    varUA.indexOf("ipad") > -1 ||
    varUA.indexOf("ipod") > -1
  ) {
    //IOS
    return "ios";
  } else {
    //아이폰, 안드로이드 외
    return "other";
  }
};

/**
 * url 통해 page id를 가지고 옴
 * 예) Helper.getPageId(url)
 * @params
 *  - url : 현재 url
 */
export const getPageId = (url) => {
  let retValue = "home";
  try {
    if(url.indexOf("/") >= 0) {
      let tmp = url.split('/');
      if(tmp.length >= 2)  {
        retValue = tmp[1];
      }
    }
  }
  catch(e) {
    console.log('error', e);
  }

  if(isEmpty(retValue))  {
    retValue = "home";
  }

  // if (url.indexOf("/blog") >= 0) {
  //   retValue = "blog";
  // } else if (url.indexOf("/photo") >= 0) {
  //   retValue = "photo";
  // } else if (url.indexOf("/portfolio") >= 0) {
  //   retValue = "portfolio";
  // } else if (url.indexOf("/coding") >= 0) {
  //   retValue = "coding";
  // } else if (url.indexOf("/lotto_import") >= 0) {
  //   retValue = "lotto_import";
  // } else if (url.indexOf("/lotto") >= 0) {
  //   retValue = "lotto";
  // } else if (url.indexOf("/test") >= 0) {
  //   retValue = "test";
  // } else if (url.indexOf("/korail_import") >= 0) {
  //   retValue = "korail_import";
  // } else if (url.indexOf("/korail") >= 0) {
  //   retValue = "korail";
  // } else if (url.indexOf("/subway_import") >= 0) {
  //   retValue = "subway_import";
  // } else if (url.indexOf("/subway") >= 0) {
  //   retValue = "subway";
  // } else if (url.indexOf("/ccu_import") >= 0) {
  //   retValue = "ccu_import";
  // } else if (url.indexOf("/ccu") >= 0) {
  //   retValue = "ccu";
  // } else if (url.indexOf("/album") >= 0) {
  //   retValue = "album";
  // } else if (url.indexOf("/link") >= 0) {
  //   retValue = "link";
  // } else if (url.indexOf("/todo_import") >= 0) {
  //   retValue = "todo_import";
  // } else if (url.indexOf("/todo") >= 0) {
  //   retValue = "todo";
  // } else if (url.indexOf("/finance_import") >= 0) {
  //   retValue = "finance_import";
  // } else if (url.indexOf("/finance") >= 0) {
  //   retValue = "finance";
  // } else if (url.indexOf("/login") >= 0) {
  //   retValue = "login";
  // } else if (url.indexOf("/logout") >= 0) {
  //   retValue = "logout";
  // } else if (url.indexOf("/my") >= 0) {
  //   retValue = "my";
  // }
  return retValue;
};

/**
 * pageId, category를 통해 네이버 블로그의 카테고리 번호를 가지고 옴
 * 예) Helper.getPageId(url)
 * @params
 *  - pageId : pageId
 *  - category : category
 */
export const getBlogCategoryNo = (pageId, category) => {
  let retValue = 0;
  if (pageId === "blog") {
    retValue = 105;
    if (category === "review") {
      retValue = 39;
    } else if (category === "etc") {
      retValue = 90;
    }
  } else if (pageId === "photo") {
    retValue = 62;
  } else if (pageId === "portfolio") {
    retValue = 126;
  } else if (pageId === "coding") {
    retValue = 89;
    if (category === "server") {
      retValue = 25;
    } else if (category === "etc") {
      retValue = 33;
    } else if (category === "database") {
      retValue = 31;
    } else if (category === "code") {
      retValue = 24;
    } else if (category === "error") {
      retValue = 109;
    }
  }
  return retValue;
};

/**
 * 뒤로가기
 * 예) Helper.goBack(-1)
 * @params
 *  - value : back할 만큼의 페이지 수
 */
export const goBack = (history) => {
  let browser_name = "";
  const browser = checkBrowser();
  if(browser) {
    if (browser.name && browser.name !== undefined) {
      browser_name = browser.name;
    }
  }
  browser_name = browser_name.toLowerCase();

  if(getOS() === "ios" && browser_name.indexOf("safari") >= 0) {
    history.go(-2);
  }
  else {
    history.goBack();
  }
};

/**
 * 최상단 스크롤 이동 (top)
 * 예) Helper.moveScroll(0)
 * @params
 *  - position : 상단에 이동할 만큼의 화면 y 위치
 */
export const moveScroll = (position) => {
  document.body.scrollTop = position; // For Safari
  document.documentElement.scrollTop = position; // For Chrome, Firefox, IE and Opera
};

/**
 * 값의 undefined, null, "" 체크
 * 예) IsEmpty(value)
 * @params
 *  - value : 체크할 값
 */
export const isEmpty = (value) => {
  if (
    value === "" ||
    value === "undefined" ||
    value === "null" ||
    value === undefined ||
    value === null ||
    (value !== null &&
      String(typeof value).toLowerCase() === "object" &&
      !Object.keys(value).length)
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * 로그_page 저장
 * @params
 *  - page_id : 현재 page
 *  - contents : object type
 */
let prev_page_id = "";
export const setLog = async (page_id, contents) => {  
  try {
    if (window.aos.isApp() === true) {
      contents.Native = true;
    } 
    else {
      contents.Native = false;
    }
  } 
  catch (e) {
    contents.Native = false;
  }

  //이전 page와 현재 page가 같으며, open일 경우에는 재호출 되지 않도록 함
  if(!isEmpty(prev_page_id) && !isEmpty(page_id)) {
    if(prev_page_id === page_id && contents.Action === "open") {
      return;
    }
  }

  try {
    const browser = checkBrowser();
    if(browser) {
      if (browser.name && browser.name !== undefined) {
        contents.Browser = browser.name;
      }

      if (browser.name && browser.version && browser.version !== undefined) {
        contents.Browser = contents.Browser + " " + browser.version;
      }
    }

    if(window.location.host.indexOf("localhost") >= 0) {
      console.log('[jo] page_id', page_id);
      console.log('[jo] contents', contents);
    }
    else {
      await axios.post("/api/common/setLog", {
        page_id: page_id,
        contents: JSON.stringify(contents),
      });
    }
    prev_page_id = page_id;
  }
  catch (error) {
    console.log("setLog error ", );
  }
};

export const checkBrowser = () => {
  let ua = window.navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: (tem[1] || '') };
  }
  if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/)
      if (tem != null) { return { name: 'Opera', version: tem[1] }; }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
  return {
      name: M[0],
      //version: M[1]
  };
}


/**
 * 세션 존재 여부
 * 예) Helper.isSession()
 */
export const isSession = () => {
  const session = window.sessionStorage.getItem("UserInfo");  
  if (session) {
    return true;
  } else {
    return false;
  }
};

/**
 * 세션 값 (사용자정보)
 * 예) Helper.getSession()
 */
export const getSession = () => {
  const session = window.sessionStorage.getItem("UserInfo");  
  if (session) {
    return JSON.parse(session);
  } else {
    return null;
  }
};

export const getSessionAsync = async () => {
  const session = window.sessionStorage.getItem("UserInfo");  
  if (session) {
    return JSON.parse(session);
  } else {
    await axios.get("/api/login/getSession")
              .then ((response)=> {
                const data = response.data;                
                if(data) {
                  if(data.retData === "EXIST") {
                    window.sessionStorage.clear();
                    window.sessionStorage.setItem("SessionID", data.retMsg.SessionID);
                    window.sessionStorage.setItem("UserInfo", JSON.stringify(data.retMsg.UserInfo));
                    return JSON.stringify(data.retMsg.UserInfo);
                  }
                  else {
                    return null;
                  }
                }
                else {
                  return null;
                }
              })
              .catch( (e)=> {
                return null;
              });
  }
};


/**
 * 세션 값 (세션 ID)
 * 예) Helper.getSessionId()
 */
export const getSessionId = () => {    
  const session = window.sessionStorage.getItem("SessionID");  
  if (session) {
    return session;
  } else {
    return null;
  }
};

/**
 * console.log
 * 예) Helper.log(obj)
 */
export const log = (message, obj) => {    
  console.log('[jo] ' + message, obj);
};


export const setLocalstorage = (k, id) => {
  const saveData = window.localStorage.getItem(k);  
  if (saveData) {
    axios.post("/api/login/setLocalStorage", {
      k : k,
      v : saveData,
      login_id : id
    })
    .then ((response)=> {
      const data = response.data;
    })
    .catch( (e)=> {
      console.log('catch', e);
    });
  }
}


export const loginPass = (data, loginId, history) => {
  window.sessionStorage.clear();
  window.sessionStorage.setItem("SessionID", data.retMsg.SessionID);
  window.sessionStorage.setItem("UserInfo", JSON.stringify(data.retMsg.UserInfo));

  //if(NativeIF.fn_isApp()) {
    window.localStorage.removeItem("localLoginID");
    window.localStorage.setItem("localLoginID", loginId);
  //}

  if(data.retData === "NEED TO CHANGE PASSWORD") {
    alert("비밀번호를 변경하세요.");
    history.push("/login/Password");
  }
  else {
    getLocalStorage(loginId, history);
  }
}

export const getLocalStorage = async (loginId, history) => {
  const retData = await axios.post("/api/login/getLocalStorage", {
    login_id: loginId
  });

  try {
    if (!isEmpty(retData.data)) {
      const data = retData.data;
      if (data.statusCode === 200) {
        if (data.retMsg === "SUCCESS") {
          const t = data.retData;

          t && t.forEach(item => {
            window.localStorage.removeItem(item.Key);
            window.localStorage.setItem(item.Key, item.Value);
          });
        }
        else {
          console.log("FAIL", data.retMsg);
        }
      } else {
        console.log("statusCode local", data.statusCode);
      }
    }
    else {
      console.log("empty local");
    }
  }
  catch (e) {
    console.log("error", e);
  }    
  finally {
    if(window.location.pathname === "/") {
      history.replace("/");

      const icon = document.querySelector(".fa.fa-user");
      if(!isEmpty(icon)) {
        setTimeout(() => {
          icon.classList.remove('fa');
          icon.classList.remove('fa-user');
          icon.classList.add('fas');
          icon.classList.add('fa-user-cog');          
        }, 500);
      }
    }
    else {
      history.push("/");
    }
  }
}