import React, { useState, useEffect } from "react";

/* module */
import axios from "axios";

/* common */
import * as Helper from "../common/Helper.js";

/* css */

const KorailView = (props) => {
  useEffect(() => {
    const contents = {
      Action: "view",
      PathName: props.location.pathname,
      TrainNo: props.trainNo,
      RunDate: props.runDate,
    };
    Helper.setLog("korail", contents); //page_id, contents

    getTrainView(props.trainNo, props.runDate);
  }, []);

  const [trainTitle, setTrainTitle] = useState("");
  const [trainList, setTrainList] = useState([]);
  const getTrainView = async (trainNo, runDate) => {
    try {
      let url =
        "/api/korail/getTrainInfo?txtTrnNo=" +
        Helper.lpad(trainNo, 5, "0") +
        "&txtRunDt=" +
        runDate;
      const { data } = await axios.get(url);

      if (data) {
        if (data.statusCode === 200) {
          setTrainTitle(data.retTitle);
          setTrainList(data.retData);
        } else {
          console.log("axios error");
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <>
      <div
        className="w3-card-4 w3-margin w3-white"
        style={{ paddingBottom: "70px" }}
      >
        <div className="w3-container w3-blue">
          <h4>{trainTitle}</h4>
        </div>
        
        <div className="w3-container w3-padding">
          <p
            className="w3-left"
            style={{ marginTop: "5px", marginBottom: "0px", textAlign: "left" }}
          >
            <b>※ 지연시간은 5분 이상 일 경우에만 표시</b>
          </p>
          <table className="w3-table-all w3-hoverable">
            <thead>
              <tr className="w3-green">
                <th className="w3-center" style={{ width: "25%" }}>
                  역명
                </th>
                <th className="w3-center" style={{ width: "25%" }}>
                  도착시각
                </th>
                <th className="w3-center" style={{ width: "25%" }}>
                  출발시각
                </th>
                <th className="w3-center" style={{ width: "25%" }}>
                  지연시간(분)
                </th>
              </tr>
            </thead>
            <tbody>
              {trainList &&
                trainList.map((item, idx) => {
                  return (
                    <tr key={"tr-" && idx}>
                      <td className="w3-center">{item.station}</td>
                      <td className="w3-center">{item.runTime}</td>
                      <td className="w3-center">{item.dptTime}</td>
                      <td className="w3-center">{item.dlyTime}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <p
            className="w3-left"
            style={{ marginTop: "5px", marginBottom: "0px", textAlign: "left" }}
          >
            <b>※ 데이터 : 렛츠코레일</b>
          </p>
        </div>
      </div>
    </>
  );
};

export default KorailView;
