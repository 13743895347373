import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

/* common */
import * as Helper from "../common/Helper.js";

/* page */
import FinanceList from "./FinanceList";
import FinanceListFav from "./FinanceListFav";
import FinanceSearch from "./FinanceSearch";
import FinanceAdd from "./FinanceAdd";

/* css */

function Finance({ match, history, location }) {
  const pageId = Helper.getPageId(match.url);
  let pageType = match.params.page_type;

  useEffect( () => {
    const contents = {
      Action: "open",
      PathName: location.pathname
    };
    Helper.setLog("finance", contents); //page_id, contents
  }, []);

  const RenderComponent = () => {
    if (Helper.isEmpty(pageType)) {
      pageType = "list";
    }

    switch (pageType) {
      case "list":
        return (
          <FinanceList history={history} location={location} pageId={pageId} />
        );
      
      case "listFav":
        return (
          <FinanceListFav history={history} location={location} pageId={pageId} />
        );

      case "search":
        return (
          <FinanceSearch history={history} location={location} pageId={pageId} />
        );
      
      case "add":
        return (
          <FinanceAdd history={history} location={location} pageId={pageId} />
        );

      default:
        return null;
    }
  };
  const FinanceComponent = () => {
    return (
      <div className="w3-display-top" style={{ paddingBottom: "70px" }}>
        <div
          className="w3-bar w3-green"
          style={{ paddingBottom: "5px", paddingTop: "5px" }}
        >
          <NavLink to={"/" + pageId + "/list"}>
            <button className="w3-bar-item w3-button"><b>손익</b></button>
          </NavLink>

          <NavLink to={"/" + pageId + "/listFav"}>
            <button className="w3-bar-item w3-button"><b>관심종목</b></button>
          </NavLink>

          <NavLink to={"/" + pageId + "/search"}>
            <button className="w3-bar-item w3-button"><b>종목조회</b></button>
          </NavLink>

          <NavLink to={"/" + pageId + "/add"}>
            <button className="w3-bar-item w3-button"><b>보유종목관리</b></button>
          </NavLink>

          {/* <NavLink to={"/" + pageId + "/help"}>
            <button className="w3-bar-item w3-button">사용방법</button>
          </NavLink> */}
        </div>
        <div className="w3-panel">{RenderComponent()}</div>
      </div>
    );
  };

  return <FinanceComponent />;
}
export default Finance;
