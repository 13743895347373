import React from "react";
import { NavLink } from "react-router-dom";

/* common */
import * as Helper from "../common/Helper.js";

/* page */
import TestBlank from "./TestBlank";

function Test({ match, history, location }) {
  const pageId = Helper.getPageId(match.url);
  let pageType = match.params.page_type;

  const RenderComponent = (pageType) => {
    switch (pageType) {      
      default:
      case "blank":
        return <TestBlank history={history} location={location} />;

        //return null;
    }
  };
  const TestComponent = () => {
    return (
      <div className="w3-display-top">
        <div
          className="w3-bar w3-green"
          style={{ paddingBottom: "5px", paddingTop: "5px" }}
        >
          <NavLink to={"/test/blank"}>
            <button className="w3-bar-item w3-button">테스트</button>
          </NavLink>
        </div>
        <div className="w3-panel">{RenderComponent(pageType)}</div>
      </div>
    );
  };

  return <TestComponent />;
}
export default Test;
