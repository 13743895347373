import React, { useState, useEffect, Fragment } from "react";

/* module */
import Select from "react-select";
import * as SubwayCommon from "./SubwayCommon.js";

/* common */
import * as Helper from "../common/Helper.js";

/* code data */
import SubwayStation from "../../jsonData/SubwayStationCode.json";

const SubwaySearch = (props) => {
  useEffect(() => {
    // 스크롤 시 IME 닫기
    function onScroll() {
      const searchWord = document.querySelector('#searchWord');      
      searchWord && searchWord.blur();
    }
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  const [stationList, setStationList] = useState([]);
  useEffect(() => {
    let arr = [];
    let data = SubwayStation.data;
    if (!Helper.isEmpty(data)) {
      data = data.sort(function (a, b) {
        return a.station_nm < b.station_nm
          ? -1
          : a.station_nm > b.station_nm
          ? 1
          : 0;
      });

      data = data.filter((item) => {
        return ( item.subway_id !== "0" && item.subway_id !== 0 );
      });

      data.map( (item) => {
        arr.push({
          "label" : item.station_nm,
          "value" : item.station_nm,
          "line_num": item.line_num,
          "station_nm" : item.station_nm,
          "station_cd":item.station_cd,
          "subway_id":item.subway_id
        });
      });
      //전체 역 리스트
      setStationList(arr);
    };
  }, []);

  const handleKeyEvent = (e) => {
    autoCompl(document.querySelector("#searchWord").value);
  }

  const [searchList, setSearchList] = useState([]);
  const autoCompl = (value) => {
    try {
      let retData = stationList.filter((item) => {
        return ( String(item.station_nm).indexOf(value) >= 0 );
      });
      
      retData && retData.sort(function (a, b) {
        if(a.station_nm > b.station_nm) return 1;
        else if (a.station_nm < b.station_nm) return -1;
        else if (a.line_num > b.line_num) return 1;
        else if (a.line_num < b.line_num) return -1;
      });

      if(retData) {
        setSearchList(retData);
      }
      else {
        setSearchList([]);
      }
    } catch (e) {
      console.log("error", e);
    }
  };


  const renderTip = () => {
    return (
      <Fragment>
        <p
          className="w3-left"
          style={{ marginTop: "5px", marginBottom: "0px" }}
        >
          ※ (<i className="far fa-heart link-cursor w3-large" />)를 <b>클릭</b> 시 '자주가는 역' 등록/해제<br/>
          <b>※ 데이터 : 서울 열린데이터광장</b>
        </p>
      </Fragment>
    );
  };


  return (
    <Fragment>
      <div className="w3-container w3-light-gray w3-padding">
        <div className="w3-cell-row">
          <div
            className="w3-cell"
            style={{ paddingRight: "10px", width: "100%" }}
          >
            <input type="text" id={"searchWord"} className={"w3-input w3-round"} autoComplete={"off"} placeholder={"지하철(전철) 역명을 입력하세요."} onKeyUp={handleKeyEvent}  />
          </div>
        </div>
      </div>
      <div>        
        <div className="w3-right" style={{ paddingTop: "10px" }} >
          <i className="far fa-heart link-cursor w3-medium" /> 자주가는역
        </div>
        <table className="w3-table-all w3-hoverable">
          <thead>
            <tr className="w3-green">
              <th className="w3-center" style={{ width: "39%" }}>역명</th>
              <th className="w3-center" style={{ width: "39%" }}>노선</th>
              <th className="w3-center" style={{ width: "12%" }}>
                <i className={"far fa-heart"} />
              </th>
            </tr>
          </thead>
          <tbody>
            {searchList &&
              searchList.map((item, idx) => {
                let favId = String(item.line_num) + "/" + String(item.station_cd) + "/" + String(item.station_nm);

                return (
                  <tr key={"tr-" && idx}>
                    <td className="w3-center">
                      {item.station_nm}
                    </td>
                    <td className="w3-center">
                      {item.line_num}
                    </td>
                    <td className="w3-center">
                      <i
                        id={favId}
                        className={
                          SubwayCommon.getSubwayFavStyle(item) +
                          " w3-large link-cursor"
                        }
                        onClick={() => SubwayCommon.setSubwayFav(item)}
                      />
                    </td>
                  </tr>
                );
              })}
            {searchList.length === 0 ? (
              <tr>
                <td className="w3-center" colSpan={5}>
                  조회된 데이터가 없습니다.
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>     
        {renderTip()}
      </div>
      
    </Fragment>
  );
};

export default SubwaySearch;
