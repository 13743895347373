import React from "react";

function Privacy({ match, history, location }) {
  // const page_type = match.params.page_type;
  // const [arrBlogList, setArrBlogList] = useState();

  // const getBlogList = async () => {
  //   try {
  //     let url =
  //       'http://blog.naver.com/PostTitleListAsync.nhn?blogId=jochanyang&viewdate=&currentPage=1&categoryNo=126&parentCategoryNo=&countPerPage=30';
  //     const { data } = await axios(url);
  //     if (data.resultCode === 'S') {
  //     }
  //   } catch (e) {
  //     console.log('error', e);
  //   }
  // };

  // useEffect(() => {
  //   //blog list : CORS 정책으로 load할 수 없음
  //   //getBlogList();
  // }, []);

  const PrivacyComponent = () => {
    return (
      <div className="w3-padding">
        www.jochanyang.com 사이트는 개인정보를 취급하지 않으며, 사용자의 어떠한 데이터도 수집하지 않습니다.
      </div>
    );
  };

  return <PrivacyComponent />;
}
export default Privacy;
