import React, { useState, useEffect } from "react";

/* module */
import axios from "axios";

/* common */
import * as Helper from "../../common/Helper.js";

/* css */

const Toast = (props) => {
  return (
    <>Toast
    </>
  );  
};

export default Toast;