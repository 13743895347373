import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PopupAction from '../../../store/modules/popup';

/* module */
import axios from "axios";

/* common */
import * as Helper from "../../common/Helper.js";

/* css */
import "../../../styles/popup.css";

/* popup */
import SimplePopup from "./Simple";
import CheckPopup from "./Check";
import ErrorPopup from "./Error";
import ToastPopup from "./Toast";

const Popup = ({ PopupData, PopupAction }) => {
  const { type, open, bgLock } = PopupData;

  const clickBgLayout = event => {
    console.log('event', event);
    console.log('open', open);
    console.log('bgLock', bgLock);    
    console.log('event.target',  event.target.className);    
    //bgLock이 true 이며, 팝업 배경을 클릭 시 팝업이 비활성화 된다.
    if (open && !bgLock && event.target.className.indexOf('w3-modal') >= 0  ) { 
      PopupAction.setInitPopup();
    } else {
      event.preventDefault();
    }
  };

  const RenderPopup = () => {
    console.log('type', type);

    switch (type) {
      case "simple" :
        return <SimplePopup></SimplePopup>;
      case "check" :
        return <CheckPopup></CheckPopup>;
      case "error" :
        return <ErrorPopup></ErrorPopup>;
      case "toast" :
        return <ToastPopup></ToastPopup>;
      default :
        return null;
    }
  }

  return (
    <div className={open?"popup-bg-layer":""} onClick={clickBgLayout} >
      <div id="popup" className="w3-modal">
        <RenderPopup />
      </div>
    </div>
  );
  
};

export default connect(
  state => ({
    PopupData: state.Popup.popup
  }),
  dispatch => ({
    PopupAction: bindActionCreators(PopupAction, dispatch)
  })
)(Popup);