import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

/* common */
import * as Helper from "../common/Helper.js";

function SiteMap({ match, history, location }) {
  const pageId = Helper.getPageId(match.url);

  useEffect( () => {
    const contents = {
      Action: "open",
      PathName: location.pathname
    };
    Helper.setLog("sitemap", contents); //page_id, contents
  }, []);


  const RenderSessionMenu = () => {
    if(Helper.isSession()) {
      return (
        <>  
          <NavLink to={"/link"} className="w3-bar-item w3-button w3-padding-medium w3-large" >
            <i className="fas fa-link w3-hover-opacity w3-large" style={{ width: "30px", textAlign: "left" }} />
            link :: 하루한장
          </NavLink>

          <NavLink to={"/test"} className="w3-bar-item w3-button w3-padding-medium w3-large" >
            <i className="fas fa-wrench w3-hover-opacity w3-large" style={{ width: "30px", textAlign: "left" }} />
            test :: 테스트 코드 작성
          </NavLink>
        </>
      )
    }
    else {
      return null;
    }
  }  

  const SiteMapComponent = () => {
    if (
      pageId !== "album" &&
      pageId !== "korail" &&
      pageId !== "subway" &&
      pageId !== "lotto" &&
      pageId !== "todo"
    ) {
      return (
        <div className="w3-display-top w3-bar-block " style={{height:"100%", lineHeight:"30px", paddingBottom:"65px"}}>
          <NavLink to={"/"} className="w3-bar-item w3-button w3-padding-medium w3-large" >
            <i className="fa fa-home w3-hover-opacity w3-large" style={{ width: "30px", textAlign: "left" }} />
            home :: 첫화면
          </NavLink>

          <NavLink to={"/blog"} className="w3-bar-item w3-button w3-padding-medium w3-large" >
            <i className="fab fa-blogger w3-hover-opacity w3-large" style={{ width: "30px", textAlign: "left" }} />
            blog :: 일상기록
          </NavLink>

          <NavLink to={"/photo"} className="w3-bar-item w3-button w3-padding-medium w3-large" >
            <i className="fas fa-camera-retro w3-hover-opacity w3-large" style={{ width: "30px", textAlign: "left" }} />
            photo :: 사진
          </NavLink>

          <NavLink to={"/portfolio"} className="w3-bar-item w3-button w3-padding-medium w3-large" >
            <i className="fas fa-tasks w3-hover-opacity w3-large" style={{ width: "30px", textAlign: "left" }} />
            portfolio :: 프로젝트
          </NavLink>

          <NavLink to={"/coding"} className="w3-bar-item w3-button w3-padding-medium w3-large" >
            <i className="fas fa-code w3-hover-opacity w3-large" style={{ width: "30px", textAlign: "left" }} />
            coding note :: 코딩메모
          </NavLink>

          <NavLink to={"/album_import"} className="w3-bar-item w3-button w3-padding-medium w3-large" >
            <i className="fas fa-users w3-hover-opacity w3-large" style={{ width: "30px", textAlign: "left" }} />
            album :: 사진공유 - 가족/지인의 사진공유
          </NavLink>

          <NavLink to={"/lotto_import"} className="w3-bar-item w3-button w3-padding-medium w3-large" >
            <i className="fas fa-dot-circle w3-hover-opacity w3-large" style={{ width: "30px", textAlign: "left" }}/>
            lotto :: 로또번호 - 로또번호 생성기
          </NavLink>

          <NavLink to={"/korail_import"} className="w3-bar-item w3-button w3-padding-medium w3-large" >
            <i className="fas fa-train w3-hover-opacity w3-large" style={{ width: "30px", textAlign: "left" }} />
            korail :: 열차정보 - 지연 및 정차역 시간 정보
          </NavLink>

          <NavLink to={"/subway_import"} className="w3-bar-item w3-button w3-padding-medium w3-large" >
            <i className="fa fa-subway w3-hover-opacity w3-large" style={{ width: "30px", textAlign: "left" }} />
            subway :: 지하철역 - 실시간 도착 정보
          </NavLink>

          <NavLink to={"/finance_import"} className="w3-bar-item w3-button w3-padding-medium w3-large" >
            <i className="fas fa-chart-line w3-hover-opacity w3-large" style={{ width: "30px", textAlign: "left" }} />
            finance :: 증권정보 - 간단 손익 계산 등
          </NavLink>

          <RenderSessionMenu />
        </div>
      );
    } else {
      return null;
    }
  };

  return <SiteMapComponent />;
}
export default SiteMap;
