import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";

/* module */
import axios from "axios";
import * as FinanceCommon from "./FinanceCommon.js";

/* common */
import * as Helper from "../common/Helper.js";
import FinanceList from "./FinanceList.js";

const FinanceListFav = (props) => {
  String.prototype.replaceAll = function (org, dest) {
    return this.split(org).join(dest);
  };

  useEffect(() => {
    getFinanceFavList();
  }, []);

  const [financeList, setFinanceList] = useState([]);

  //전체 조회(처음 화면 진입시)
  const getFinanceFavList = () => {
    let data = JSON.parse(localStorage.getItem("localFinanceFav"));
    if (Helper.isEmpty(data)) {
      setFinanceList([]);
      return;
    }

    let param = "";
    data && data.map((obj) => {
      if(param.length === 0) {
        param = obj.cd;
      }
      else {
        param = param + "," + obj.cd;
      }
    });

    if(param && param.length > 0) {
      axios
      .get("/api/finance/getRealtimeList?code=" + param)
      .then((data) => {
        if ( data && data.data && data.data.retData) {          
          let retData = data.data.retData;
          getFinancePrchList(retData);
        }
        else {
          setFinanceList([]);
        }
      });
    }
    else {
      setFinanceList([]);
    }
  };

  const getFinancePrchList = (favList) => {
    let data = JSON.parse(localStorage.getItem("localFinancePrch"));
    if (Helper.isEmpty(data)) {
      setFinanceList(favList);
      return;
    }

    let retData = favList;
    retData && retData.sort(function (a, b) {
      if(a.aq > b.aq) return -1
      else if(a.aq < b.aq) return 1
    });

    setFinanceList(retData);
  }

  const renderTip = () => {
    if (financeList && financeList.length > 0) {
      return (
        <Fragment>
          <p
            className="w3-left"
            style={{ marginTop: "5px", marginBottom: "0px", textAlign: 'left' }}
          >
            ※ 차트 클릭 시 '네이버 증권' 으로 이동욉니다.<br />
            <b>※ 데이터(현재가) : 네이버 증권</b>
          </p>
        </Fragment>
      );
    }
  };

  const label = (pcv, nv) => {
    if(Helper.isEmpty(pcv) || Helper.isEmpty(nv)) {
      return "-";
    }

    let value = nv - pcv;
    if(value > 0) {
      return (
        (<span style={{color:'red'}}>(+{value.toLocaleString()})</span>)
      )
    }
    else if(value < 0) {
      return (
        <span style={{color:'blue'}}>({value.toLocaleString()})</span>
      )
    }
    else {
      return value.toLocaleString()
    }
  }

  return (
    <Fragment>
      <div className="w3-center">

        <div className="w3-right">
          <i className="fas fa-star link-cursor w3-medium" /> 관심종목
        </div>
        <table className="w3-table-all w3-hoverable">
          <thead>
            <tr className="w3-green">
              <th className="w3-center" style={{ width: "30%" }}>종목명</th>     
              <th className="w3-center" style={{ width: "24%" }}>현재가<br/>전일대비</th>
              <th className="w3-center" style={{ width: "18%" }}>전일<br/>시가</th>
              <th className="w3-center" style={{ width: "18%" }}>고가<br/>저가</th>
              <th className="w3-center" style={{ width: "10%" }}>
                <i className={"fas fa-star w3-large link-cursor"} />
              </th>              
            </tr>
          </thead>
          <tbody>
              {financeList && financeList.map((item, idx) => {
                let favId = String(item.cd);
                let naver_url = 'https://finance.naver.com/item/main.nhn?code=' + item.cd;
                return (
                  <tr key={"tr-" && idx}>
                    <td className="w3-center">
                      {item && item.nm}<br/>
                      <img src={"https://ssl.pstatic.net/imgfinance/chart/item/area/day/" + item.cd + ".png"} className={"link-cursor"} onClick={() => Helper.goPopup(naver_url, 'finance')} />
                    </td>
                    <td className="w3-center">
                      <b>{item.nv && item.nv.toLocaleString()}</b><br/>{label(item.pcv, item.nv)}
                    </td>
                    <td className="w3-right">
                      {item.sv && item.sv.toLocaleString()}<br/>{item.ov && item.ov.toLocaleString()}
                    </td>
                    <td className="w3-center">
                      {item.hv && item.hv.toLocaleString()}<br/>{item.lv && item.lv.toLocaleString()}
                    </td>
                    <td className="w3-center">
                      <i
                        id={'fav_' + favId}
                        className={
                          FinanceCommon.getFinanceFavStyle(item) +
                          " w3-large link-cursor"
                        }
                        onClick={() => FinanceCommon.setFinanceFav(item)}
                      />
                    </td>
                  </tr>
                );
              })}

              {financeList.length === 0 ? (
                <tr>
                  <td className="w3-center" colSpan={5}>
                    <NavLink to={"/" + props.pageId + "/search"}>
                      <span style={{ fontWeight: "bold" }}>
                        '종목조회' 후 관심종목을 등록하세요.
                      </span>
                    </NavLink>
                  </td>
                </tr>
            ) : null}
          </tbody>
        </table>
        {financeList.length === 0 ? (
          <NavLink to={"/" + props.pageId + "/search"}>
            <img
              src={"/image/finance/guide.png"}
              className={"link-cursor"}
              style={{ maxWidth: "393px", width: "100%", paddingTop: "20px" }}
            />
          </NavLink>
        ) : renderTip()
        }
      </div>
    </Fragment>
  );
};

export default FinanceListFav;
