import React, { useState, useEffect } from "react";

/* module */
import axios from "axios";
import Select from "react-select";

const AlbumCreate = (props) => {
  const [arrAlbumGrp, setArrAlbumGrp] = useState([]);
  const [arrAlbumGrpSelected, setArrAlbumGrpSelected] = useState({});
  useEffect(() => {
    getListAlbumGrp();
  }, []);

  const getListAlbumGrp = async () => {
    try {
      let url = "/api/album/getListAlbumGrp";
      const { data } = await axios.post(url, {
        album_key: props.albumKey,
      });

      if (data) {
        if (data.statusCode === 200) {
          let retData = data.retData;

          let arr = [];
          retData.map((item, idx) => {
            arr.push({
              value: item.Code,
              label: item.CodeName,
            });
          });

          setArrAlbumGrp(arr);
          if (arr.length > 0) {
            setArrAlbumGrpSelected(arr[0]);
            getListAlbum(arr[0]);
          }
        } else {
          setArrAlbumGrpSelected([]);
          setAlbumList([]);
          console.log("axios error");
        }
      }
    } catch (e) {
      setArrAlbumGrpSelected([]);
      setAlbumList([]);
      console.log("error", e);
    }
  };

  const [albumList, setAlbumList] = useState([]);
  const getListAlbum = async (selected) => {
    try {
      let url = "/api/album/getListAlbum";
      const { data } = await axios.post(url, {
        album_grp: selected.value,
        album_key: props.albumKey,
      });

      if (data) {
        if (data.statusCode === 200) {
          let retData = data.retData;
          retData = retData.sort(function (a, b) {
            return a.SortSeq > b.SortSeq ? -1 : a.SortSeq < b.SortSeq ? 1 : 0;
          });
          setAlbumList(retData);
        } else {
          setAlbumList([]);
          //console.log("axios error");
        }
      }
    } catch (e) {
      console.log("error", e);
      setAlbumList([]);
    }
  };

  const arrAlbumGrpChange = (e) => {
    setArrAlbumGrpSelected(e);
    getListAlbum(e);
  };

  const handleSubmit = (e) => {
    exports.preventDefault();
    const formData = new FormData();
    formData.append('AlbumGrp',e.target.AlbumGrp.value);
    formData.append('AlbumUrl',e.target.AlbumUrl.value);
    formData.append('AlbumName', e.target.AlbumName.value);
    formData.append('SortSeq', e.target.SortSeq.value);
    formData.append('AlbumThumb', e.target.AlbumThumb.files[0]);
    console.log('formData', formData);
return;
    register(formData)
  }

  const register = (formData) => {
    let url = "/api/album/getListAlbum";
    fetch(url, {
      method:'post',
      body: formData
    })
    .then(res => res.json())
    .then(data => alert(data.msg))
  }


  return (
    <form onSubmit={handleSubmit} encType='multipart/form-data' className="w3-container">
      <label className="w3-text-blue"><b>앨범 그룹</b></label>
      <Select
        id="AlbumGrp"
        placeholder={"앨범 그룹"}
        options={arrAlbumGrp}
        onChange={arrAlbumGrpChange}
        isSearchable={false}
        value={arrAlbumGrpSelected}
      />

      <label className="w3-text-blue"><b>앨범 URL</b></label>
      <input className="w3-input w3-border" type="text" id="AlbumUrl"/>
      
      <label className="w3-text-blue"><b>앨범 제목</b></label>
      <input className="w3-input w3-border" type="text" id="AlbumName"/>
      
      <label className="w3-text-blue"><b>정렬 순서</b></label>
      <input className="w3-input w3-border" type="text" id="SortSeq"/>

      <label className="w3-text-blue"><b>썸네일 이미지</b></label>
      <input className="w3-input w3-border" type="file" id="AlbumThumb"/>

      <button className="w3-btn w3-blue">등록</button>
    </form>
  );
};

export default AlbumCreate;
