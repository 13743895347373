import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";

/* module */
import axios from "axios";
import * as InstaCommon from "./InstaCommon.js";

/* common */
import * as Helper from "../common/Helper.js";
import InstaList from "./InstaList.js";

const InstaListFav = (props) => {
  String.prototype.replaceAll = function (org, dest) {
    return this.split(org).join(dest);
  };

  useEffect(() => {
    getInstaFavList();
  }, []);

  const [financeList, setInstaList] = useState([]);

  //전체 조회(처음 화면 진입시)
  const getInstaFavList = () => {
    let data = JSON.parse(localStorage.getItem("localInstaFav"));
    if (Helper.isEmpty(data)) {
      setInstaList([]);
      return;
    }

    let param = "";
    data && data.map((obj) => {
      if(param.length === 0) {
        param = obj.cd;
      }
      else {
        param = param + "," + obj.cd;
      }
    });

    if(param && param.length > 0) {
      axios
      .get("/api/finance/getRealtimeList?code=" + param)
      .then((data) => {
        if ( data && data.data && data.data.retData) {          
          let retData = data.data.retData;
          getInstaPrchList(retData);
        }
        else {
          setInstaList([]);
        }
      });
    }
    else {
      setInstaList([]);
    }
  };

  const getInstaPrchList = (favList) => {
    let data = JSON.parse(localStorage.getItem("localInstaPrch"));
    if (Helper.isEmpty(data)) {
      setInstaList(favList);
      return;
    }

    let retData = favList;
    retData && retData.sort(function (a, b) {
      if(a.aq > b.aq) return -1
      else if(a.aq < b.aq) return 1
    });

    setInstaList(retData);
  }

  const renderTip = () => {
    if (financeList && financeList.length > 0) {
      return (
        <Fragment>
          <p
            className="w3-left"
            style={{ marginTop: "5px", marginBottom: "0px", textAlign: 'left' }}
          >
            ※ 차트 클릭 시 '네이버 증권' 으로 이동욉니다.<br />
            <b>※ 데이터(현재가) : 네이버 증권</b>
          </p>
        </Fragment>
      );
    }
  };

  const label = (pcv, nv) => {
    if(Helper.isEmpty(pcv) || Helper.isEmpty(nv)) {
      return "-";
    }

    let value = nv - pcv;
    if(value > 0) {
      return (
        (<span style={{color:'red'}}>(+{value.toLocaleString()})</span>)
      )
    }
    else if(value < 0) {
      return (
        <span style={{color:'blue'}}>({value.toLocaleString()})</span>
      )
    }
    else {
      return value.toLocaleString()
    }
  }

  return (
    <Fragment>
      <div className="w3-center">
        
      </div>
    </Fragment>
  );
};

export default InstaListFav;
