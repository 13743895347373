import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";

/* module */
import axios from "axios";
import moment from "moment";

/* common */
import * as Helper from "../common/Helper.js";

const ProfitList = (props) => {
  String.prototype.replaceAll = function (org, dest) {
    return this.split(org).join(dest);
  };

  
  const [time, setTime] = useState("");

  useEffect(() => {
    //local storage의 구매 리스트 받아오기
    getLocalProfitPrchList();

  }, []);

  const [financeList, setProfitList] = useState([]);

  //전체 조회(처음 화면 진입시)
  const getLocalProfitPrchList = () => {    
    //현재 시간 표시
    setTime(moment(new Date()).format("HH:mm:ss"));

    let data = JSON.parse(localStorage.getItem("localProfitPrch"));
    if (Helper.isEmpty(data)) {
      setProfitList([]);
      return;
    }

    let param = "";
    data && data.map((obj) => {
      if(param.length === 0) {
        param = obj.cd;
      }
      else {
        param = param + "," + obj.cd;
      }
    });

    if(param && param.length > 0) {
      axios
      .get("/api/finance/getRealtimeList?code=" + param)
      .then((data) => {
        if ( data && data.data && data.data.retData) {          
          let retData = data.data.retData;
          getProfitPrchList(retData);
        }
        else {
          setProfitList([]);
        }
      });
    }
    else {
      setProfitList([]);
    }
  };

  const getProfitPrchList = (favList) => {
    let data = JSON.parse(localStorage.getItem("localProfitPrch"));
    if (Helper.isEmpty(data)) {
      setProfitList(favList);
      return;
    }

    let retData = [];
    let total = 0;
    let totalGab = 0;
    let totalPrch = 0;
    favList.map( (item) => {
      let filter = data.filter( (obj) => {
        return item.cd === obj.cd;
      });
      let prch_price = (filter && filter[0] && filter[0].prch_price)? parseInt(filter[0].prch_price):0;
      let prch_qty = (filter && filter[0] && filter[0].prch_qty)? parseInt(filter[0].prch_qty):0;
      let price_toal = 0;
      let prch_total = 0;
      
      //평가금액
      if(prch_price === 0 && prch_qty === 0) {
        price_toal = 0;
      }
      else {
        price_toal = (parseInt(item.nv) * prch_qty);
      }

      //단가차이
      let prch_gab = parseInt(item.nv) - parseInt(prch_price);
      if(parseInt(item.nv) === prch_gab) {
        prch_gab = 0;
      }
      
      //매수금액
      prch_total = (parseInt(prch_price) * parseInt(prch_qty));

      //평가금액-매수금액
      let price_gab = price_toal - prch_total;

      prch_price = prch_price.toLocaleString();
      prch_qty = prch_qty.toLocaleString();

      //세금계산
      let tax = parseInt(price_toal * 0.0025);
      if(String(item.nm).indexOf("(H)") >= 0) {
        tax = 0;
      }
      price_gab = price_gab - tax;

      retData.push({...item, prch_price, prch_qty, prch_total, prch_gab, price_toal, price_gab, tax});

      total = total + price_toal;
      totalGab = totalGab + price_gab;
      totalPrch = totalPrch + prch_total;
    });
    retData && retData.sort(function (a, b) {
      if(a.price_toal > b.price_toal) return -1
      else if(a.price_toal < b.price_toal) return 1
      else if(a.prch_total > b.prch_total) return -1
      else if(a.prch_total < b.prch_total) return 1
      else if(a.nm > b.nm) return 1
      else if(a.nm < b.nm) return -1
    });

    setProfitList(retData);
    setTotal(total);
    setTotalPrch(totalPrch);
    setTotalGab(totalGab);
  }

  const [total, setTotal] = useState(0);
  const [totalGab, setTotalGab] = useState(0);
  const [totalPrch, setTotalPrch] = useState(0);

  const renderTip = () => {
    if (financeList && financeList.length > 0) {
      return (
        <Fragment>
          <p
            className="w3-left"
            style={{ marginTop: "5px", marginBottom: "0px", textAlign: 'left' }}
          >
            ※ 손익의 경우 세금(추정/0.25%) 반영 한 금액입니다.((H) 제외)<br />
            ※ 차트 클릭 시 '네이버 증권' 으로 이동욉니다.<br />
            <b>※ 데이터(현재가) : 네이버 증권</b>
          </p>
        </Fragment>
      );
    }
  };

  const label = (value) => {
    if(value > 0) {
      return (
        <span style={{color:'red'}}>(+{value.toLocaleString()})</span>
      )
    }
    else if(value < 0) {
      return (
        <span style={{color:'blue'}}>({value.toLocaleString()})</span>
      )
    }
    else {
      return ""
    }
  }

  return (
    <Fragment>
      <div className="w3-center">

        <div className="w3-right" >
          <i className="far fa-clock link-cursor w3-medium" /> <span id="time">{time}</span> / <i className="fas fa-redo link-cursor w3-medium" onClick={() => getLocalProfitPrchList() }/> 재조회
        </div>
        <table className="w3-table-all w3-hoverable">
          <thead>
            <tr className="w3-green">
              <th className="w3-center">종목명</th>     
              <th className="w3-center" style={{ width: "25%" }}>현재가<br/>매수가</th>
              <th className="w3-center" style={{ width: "15%" }}>보유<br/>수량</th>
              <th className="w3-center" style={{ width: "30%" }}>평가금액<br/>매수금액<br/>(손익)</th>
            </tr>
          </thead>
          <tbody>
              {financeList && financeList.map((item, idx) => {
                let naver_url = 'https://finance.naver.com/item/main.nhn?code=' + item.cd;
                return (
                  <tr key={"tr-" && idx}>
                    <td className="w3-center">
                      <b>{item && item.nm}</b><br/>
                      <img src={"https://ssl.pstatic.net/imgfinance/chart/item/area/day/" + item.cd + ".png"} className={"link-cursor"} onClick={() => Helper.goPopup(naver_url, 'finance')} />
                    </td>
                    <td className="w3-center">
                      <b>{item.nv && item.nv.toLocaleString()}</b><br/>{item.prch_price}<br/>{label(item.prch_gab)}
                    </td>
                    <td className="w3-center">
                      <b>{item.prch_qty && item.prch_qty.toLocaleString()}</b>
                    </td>
                    <td className="w3-center">
                      <b>{item.price_toal.toLocaleString()}</b><br/>{item.prch_total.toLocaleString()}<br/>{label(item.price_gab)}
                    </td>
                  </tr>
                );
              })}

              {total && total !== 0 ? (
                <tr>
                  <td className="w3-center" colSpan={3}>
                    <b>평가금액 합계</b><br/>
                    <b>매수금액 합계</b><br/>
                    <a href="#" onClick={()=>Helper.goPopup("https://docs.google.com/spreadsheets/d/1cnKHYqjDw3jv-KLAwmnrYfsFTRZRbT2vdFVaiI3ZLuU/edit?usp=sharing", "finance")}><b>상세내역보기</b></a>
                  </td>
                  <td className="w3-center">
                    <b>{total.toLocaleString()}</b><br/>{totalPrch.toLocaleString()}<br/><b>{label(totalGab)}</b>
                  </td>
                </tr>
              ):null}       

              {financeList.length === 0 ? (
                <tr>
                  <td className="w3-center" colSpan={4}>
                    <NavLink to={"/" + props.pageId + "/search"}>
                      <span style={{ fontWeight: "bold" }}>
                        '종목조회' 후 보유종목을 등록하세요.
                      </span>
                    </NavLink>
                  </td>
                </tr>
            ) : null}
          </tbody>
        </table>
        {financeList.length === 0 ? (
          <NavLink to={"/" + props.pageId + "/search"}>
            <img
              src={"/image/finance/guide.png"}
              className={"link-cursor"}
              style={{ maxWidth: "393px", width: "100%", paddingTop: "20px" }}
            />
          </NavLink>
        ) : renderTip()
        }
      </div>
    </Fragment>
  );
};

export default ProfitList;
