import React, { useState, useEffect } from "react";

/* module */
import axios from "axios";
import Dotdotdot from "react-dotdotdot";
import InfiniteScroll from "react-infinite-scroll-component";
import LazyLoad from "react-lazyload";

/* common */
import * as Helper from "../common/Helper.js";

const BlogThumb = (props) => {
  String.prototype.replaceAll = function (org, dest) {
    return this.split(org).join(dest);
  };

  const categoryNo = props.categoryNo;
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getPostCount();
  }, []);

  const [postCount, setPostCount] = useState(0);
  const getPostCount = async () => {
    if (categoryNo === 0) return;

    try {
      let url = "/api/blog/getPostCount?categoryNo=" + categoryNo;
      const { data } = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });

      if (data) {
        if (data.statusCode === 200) {
          //console.log("axios success");
          setPostCount(parseInt(data.retData));
        } else {
          //console.log("axios error");
        }
      }
    } catch (e) {
      console.log("error", e);
    }

    getPostList(1); //currentPage, countPerPage
  };

  const [postList, setPostList] = useState([]);
  const getPostList = async (curPage) => {
    setCurrentPage(curPage);
    try {
      let url =
        "/api/blog/getPostThum?categoryNo=" +
        categoryNo +
        "&currentPage=" +
        curPage;
      const { data } = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });

      if (data) {
        if (data.statusCode === 200) {
          //console.log("axios success");
          setPostList(postList.concat(data.retData));
        } else {
          //console.log("axios error");
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const fetchMoreData = () => {
    // a fake async api call like which sends
    if (postList.length < postCount) {
      setTimeout(() => {
        //데이터 불러오는 메세지 출력하기 위해 지연 효과를 줌
        getPostList(currentPage + 1);
      }, 150);
    }
  };

  const goView = (contents, pageId, logNo) => {
    contents.Action = "view";
    contents.PathName = props.location.pathname;
    Helper.setLog(pageId, contents); //page_id, contents

    Helper.goPost(logNo);
  };

  return (
    <div className="w3-container" style={{ paddingBottom: "70px" }}>
      <InfiniteScroll
        dataLength={postList.length}
        next={fetchMoreData}
        hasMore={true}
      >
        <LazyLoad once={true}>
          <ul style={{ listStyle: "disc" }} className="w3-center">
            {postList &&
              postList.map((item, idx) => {
                item.title = String(item.title).replaceAll("&lt;", "<");
                item.title = String(item.title).replaceAll("&rt;", ">");
                const link = String(item.link).split("&");
                let logNo = "";
                if (link) {
                  logNo = String(link[1]).replaceAll("logNo=", "");
                }
                return (
                  <li
                    key={"li-idx-" + idx}
                    className="item link-cursor"
                    onClick={() => {
                      goView(
                        {
                          Title: decodeURIComponent(
                            String(item.title).replace(/\+/g, " ")
                          ),
                          PostNo: logNo,
                          CategoryNo: categoryNo,
                        },
                        props.pageId,
                        String(logNo)
                      );
                    }}
                  >
                    <div className="area_thumb w3-border w3-signal-white">
                      <img src={item.thumb} />
                    </div>
                    <div className="area_text">
                      <Dotdotdot clamp={1}>{item.title}</Dotdotdot>
                    </div>
                  </li>
                );
              })}
          </ul>
        </LazyLoad>
      </InfiniteScroll>
    </div>
  );
};

export default BlogThumb;
