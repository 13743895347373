import React from "react";
import { Switch } from "react-router-dom";

/* page */
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

/* common */
import SubIndexRoutes from "./components/SubIndexRoutes";

/* popup */
import { Popup } from "./components/common/popup"

function AppBody({pageId, version}) {
  return (
    <>
      <div id="wrapper">
        <Header pageId={pageId} version={version} />
        <Switch>
          <SubIndexRoutes pageId={pageId} version={version} />
        </Switch>
        <Footer pageId={pageId} version={version} />
      </div>
      <Popup></Popup>
    </>
  );
}

export default AppBody;
