import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";

/* module */
import axios from "axios";
import * as FinanceCommon from "./FinanceCommon.js";

/* common */
import * as Helper from "../common/Helper.js";

const FinanceAdd = (props) => {
  String.prototype.replaceAll = function (org, dest) {
    return this.split(org).join(dest);
  };

  useEffect(() => {
    getFinanceFavList();
  }, []);

  const [financeList, setFinanceAdd] = useState([]);

  //전체 조회(처음 화면 진입시)
  const getFinanceFavList = () => {
    let data = JSON.parse(localStorage.getItem("localFinancePrch"));
    if (Helper.isEmpty(data)) {
      setFinanceAdd([]);
      return;
    }

    let param = "";
    data && data.map((obj) => {
      if(param.length === 0) {
        param = obj.cd;
      }
      else {
        param = param + "," + obj.cd;
      }
    });

    if(param && param.length > 0) {
      axios
      .get("/api/finance/getRealtimeList?code=" + param)
      .then((data) => {
        if ( data && data.data && data.data.retData) {          
          let retData = data.data.retData;
          getFinancePrchList(retData);
        }
        else {
          setFinanceAdd([]);
        }
      });
    }
    else {
      setFinanceAdd([]);
    }
  };

  const getFinancePrchList = (favList) => {
    let data = JSON.parse(localStorage.getItem("localFinancePrch"));
    if (Helper.isEmpty(data)) {
      setFinanceAdd(favList);
      return;
    }

    let retData = [];
    favList.map( (item) => {
      let filter = data.filter( (obj) => {
        return item.cd === obj.cd;
      });
      let prch_price = (filter && filter[0] && filter[0].prch_price)? parseInt(filter[0].prch_price):0;
      let prch_qty = (filter && filter[0] && filter[0].prch_qty)? parseInt(filter[0].prch_qty):0;
      let price_toal = (prch_price * prch_qty);

      retData.push({...item, prch_price, prch_qty, price_toal});
    });
    retData && retData.sort(function (a, b) {
      if(a.price_toal > b.price_toal) return -1
      else if(a.price_toal < b.price_toal) return 1
      else if(a.prch_price > b.prch_price) return -1
      else if(a.prch_price < b.prch_price) return 1
      else if(a.nm > b.nm) return 1
      else if(a.nm < b.nm) return -1
    });

    setFinanceAdd(retData);
  }


  const renderTip = () => {
    if (financeList && financeList.length > 0) {
      return (
        <Fragment>
          <p
            className="w3-left"
            style={{ marginTop: "5px", marginBottom: "0px", textAlign: 'left' }}
          >
            <b>※ 데이터(현재가) : 네이버 증권</b>
          </p>
        </Fragment>
      );
    }
  };

  return (
    <Fragment>
      <div className="w3-center">
        <div className="w3-right">
          <i className="fas fa-heart link-cursor w3-medium" /> 보유종목 / <i className="far fa-save link-cursor w3-medium" /> 수정
        </div>
        <table className="w3-table-all w3-hoverable">
          <thead>
            <tr className="w3-green">
              <th className="w3-center">종목명</th>     
              <th className="w3-center" style={{ width: "15%" }}>현재가</th>
              <th className="w3-center" style={{ width: "18%" }}>수량</th>
              <th className="w3-center" style={{ width: "20%" }}>매수단가</th>
              <th className="w3-center" style={{ width: "20%" }}>
                <i className="fas fa-heart link-cursor w3-large" /> / <i className="far fa-save link-cursor w3-large" />
              </th>
            </tr>
          </thead>
          <tbody>
              {financeList && financeList.map((item, idx) => {
                let favId = item.cd;
                return (
                  <tr key={"tr-" && idx}>
                    <td className="w3-center">
                      {item && item.nm}
                    </td>
                    <td className="w3-center w3-right">
                      <b>{item.nv && item.nv.toLocaleString()}</b>
                    </td>
                    <td className="w3-center">
                      <input type="number" id={"prch_qty_" + favId} style={{width:"100%", textAlign:"right"}} defaultValue={item.prch_qty} />
                    </td>
                    <td className="w3-center">
                      <input type="number" id={"prch_price_" + favId} style={{width:"100%", textAlign:"right"}} defaultValue={item.prch_price} />
                    </td>
                    <td className="w3-center">
                      <i
                        id={'prch_' + favId}
                        className={FinanceCommon.getFinancePrchStyle(item) + " w3-large link-cursor"}
                        onClick={() => FinanceCommon.setFinancePrch({...item, prch_qty:0, prch_price:0})}
                      />
                      {' / '}
                      <i
                        id={'save_' + favId}
                        className={"far fa-save w3-large link-cursor"
                        }
                        onClick={() => FinanceCommon.save(item)}
                      />
                    </td>
                  </tr>
                );
              })}

              {financeList.length === 0 ? (
                <tr>
                  <td className="w3-center" colSpan={4}>
                    <NavLink to={"/" + props.pageId + "/search"}>
                      <span style={{ fontWeight: "bold" }}>
                        '종목조회' 후 보유종목을 등록하세요.
                      </span>
                    </NavLink>
                  </td>
                </tr>
            ) : null}
          </tbody>
        </table>
        {financeList.length === 0 ? (
          <NavLink to={"/" + props.pageId + "/search"}>
            <img
              src={"/image/finance/guide.png"}
              className={"link-cursor"}
              style={{ maxWidth: "393px", width: "100%", paddingTop: "20px" }}
            />
          </NavLink>
        ) : renderTip()
        }
      </div>
    </Fragment>
  );
};

export default FinanceAdd;
