import React, { useEffect, useState } from "react";

/* common */
import * as Helper from "../common/Helper.js";

const LoginUser = ({ history, location }) => {
  
  const RenderMessage = () => {
    if(Helper.isSession()) {
      return (
        <div>{JSON.stringify(Helper.getSession())}</div>
      )
    }
    else {
      return null;
    }
  }

  return (
    <div className="w3-container w3-border">
      <RenderMessage />
    </div>
  );
};

export default LoginUser;
