import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PopupAction from '../../../store/modules/popup';

/* module */
import axios from "axios";

/* common */
import * as Helper from "../../common/Helper.js";

/* css */

const Simple = ({ PopupData, PopupAction }) => {
  const {
    popup: { title, msg, confirmBtnMsg, confirmFunction }
  } = PopupData;
  const { setInitPopup } = PopupAction;

  function handleConfirmBtn() {
    confirmFunction && confirmFunction();
    setInitPopup();
  }

  return (
      <div className="w3-modal-content w3-gray" style={{position:"sticky", width:"70%"}}>
        <div className="w3-panel w3-green w3-round-large">
          <span onClick={handleConfirmBtn} className="w3-button w3-display-topright">&times;</span>
          <h3>{title}</h3>
          <p>{msg}</p>
        </div>
      </div>
  );
};

export default connect(
  state => ({
    PopupData: state.Popup
  }),
  dispatch => ({
    PopupAction: bindActionCreators(PopupAction, dispatch)
  })
)(Simple);