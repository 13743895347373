import React, { Fragment, useState, useEffect } from "react";

/* module */
import parser from "html-react-parser";

/* common */
//import * as Helper from "../common/Helper.js";
import * as LottoCommon from "./LottoCommon.js";

function LottoFav(props) {
  useEffect(() => {
    getHistory();
  }, []);

  const [listHistory, setListHistory] = useState([]);
  const getHistory = () => {
    let lc = JSON.parse(localStorage.getItem("localLottoFav"));
    if (lc && lc.length > 0) {
      setListHistory(lc);
    }
  };

  //변환
  function getLottoNoConv(arr, i) {
    let str = "";
    let color = "";
    arr.forEach((no) => {
      if (no < 10) {
        color = "ball1";
      } else if (no < 20) {
        color = "ball2";
      } else if (no < 30) {
        color = "ball3";
      } else if (no < 40) {
        color = "ball4";
      } else if (no < 50) {
        color = "ball5";
      }
      str += "<span class='ball_645 sml " + color + "'>" + no + "</span>";
    });
    return str; //ball_645 sml ball1
  }

  return (
    <Fragment>
      <div className="w3-panel w3-light-gray w3-padding">
        <h5>※ 최대 50개까지만 보관 가능합니다.</h5>
        <table className="w3-table-all w3-hoverable">
          <thead>
            <tr className="w3-green">
              <th className="w3-center" style={{ width: "15%" }}>
                No
              </th>
              <th className="w3-center">보관 번호</th>
              <th className="w3-center" style={{ width: "15%" }}>
                보관
              </th>
            </tr>
          </thead>
          <tbody>
            {listHistory &&
              listHistory.map((item, idx) => {
                return (
                  <tr key={"tr-" && idx}>
                    <td className="w3-center">{idx + 1}</td>
                    <td className="w3-center">
                      {parser(getLottoNoConv(item))}
                    </td>
                    <td className="w3-center">
                      <i
                        id={String(item)}
                        className="fas fa-heart link-cursor"
                        onClick={() => LottoCommon.setLottoFav(item)}
                      />
                    </td>
                  </tr>
                );
              })}
            {listHistory.length === 0 ? (
              <tr>
                <td className="w3-center" colSpan={3}>
                  생성 이력이 없습니다.
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}

export default LottoFav;
