/* common */
import * as Helper from "../common/Helper.js";

export const setLottoFav = (arr) => {
  //fas ♥  , far ♡
  let lc = JSON.parse(localStorage.getItem("localLottoFav"));

  if (document.getElementById(String(arr)).className.indexOf("fas fa-heart") >= 0) {
    //이미 등록된 번호를 해제하기 위함
    //배열에서 arr을 찾아야 함
    //배열에서 해당 번호 삭제
    //재등록
    if (lc && lc.length >= 1) {
      localStorage.removeItem("localLottoFav");

      //동일번호가 있는지 체크
      let temp = [];
      lc && lc.map((item) => {
        if (String(item).indexOf(String(arr)) === -1) {
          temp.push(item);
        }
      });

      temp && localStorage.setItem("localLottoFav", JSON.stringify(temp));
      document.getElementById(String(arr)).className = "far fa-heart link-cursor";
    }
  } else {
    //동일번호가 있는지 체크
    let temp = [];
    lc && lc.map((item) => {
      temp.push(String(item));
    });
    if (temp.includes(String(arr))) {
      alert("동일한 번호가 '보관함'에 있습니다.");
      return;
    }

    //없는 번호를 등록
    if (lc && lc.length > 49) {
      lc = lc.slice(0, 49);
    }

    let arrNew = [arr];
    if (lc && lc.length > 0) {
      arrNew = arrNew.concat(lc);
    }

    if (arrNew && arrNew.length >= 1) {
      localStorage.setItem("localLottoFav", JSON.stringify(arrNew));
      document.getElementById(String(arr)).className = "fas fa-heart link-cursor";
    }
  }

  const UserInfo = Helper.getSession();
  if(Helper.isSession() && UserInfo && UserInfo.LoginId) {
    Helper.setLocalstorage("localLottoFav", UserInfo.LoginId);
  }       
};

export const getLottoFavIsChk = (arr) => {
  let retValue = false;

  const lc = JSON.parse(localStorage.getItem("localLottoFav"));
  let temp = [];
  lc.map((item) => {
    temp.push(String(item));
  });
  if (temp.includes(String(arr))) {
    retValue = true;
  }
  return retValue;
};

export const getLottoFavStyle = (arr) => {
  let retValue = "far fa-heart";

  const lc = JSON.parse(localStorage.getItem("localLottoFav"));
  let temp = [];
  lc && lc.map((item) => {
    temp.push(String(item));
  });
  if (temp.includes(String(arr))) {
    retValue = "fas fa-heart";
  }
  return retValue;
};
