import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";

/* module */
import axios from "axios";
import moment from "moment";
import * as SubwayCommon from "./SubwayCommon.js";

/* common */
import * as Helper from "../common/Helper.js";

const SubwayList = (props) => {
  String.prototype.replaceAll = function (org, dest) {
    return this.split(org).join(dest);
  };

  useEffect(() => {
    getSubwayList();
  }, []);

  const [trainList, setSubwayList] = useState([]);

  //전체 조회(처음 화면 진입시)
  const getSubwayList = () => {
    let data = JSON.parse(localStorage.getItem("localSubwayFav"));
    if (Helper.isEmpty(data)) {
      return;
    }
    else {
      data = data.sort(function (a, b) {
        return a.station_nm < b.station_nm
          ? -1
          : a.station_nm > b.station_nm
          ? 1
          : 0;
      });
    }
    setSubwayList(data);

    const nowTime = moment(new Date()).format("HHmmss");
    data && data.map((obj) => {
      axios
        .post("/api/subway/getArrivalList", {
          startIdx: 0,
          endIdx: 15,
          stationName: obj.station_nm,
        })
        .then((data) => {
          if (
            data &&
            data.data &&
            data.data.retData
          ) {

            const target = document.querySelector("#subway_" + obj.line_num + "_" + obj.station_cd + "_" + obj.station_nm);
            if(target) {
              target.innerHTML = "";
              
              let retData = data.data.retData;
              if(retData && retData.length > 0) {
                retData = retData.filter((item) => {
                  return ( item.subwayId === obj.subway_id ); //같은 노선만 추출
                });    
                retData = retData.filter((item) => {
                  return ( item.arvlCd !== "1" );  //도착한 열차는 제외
                });
                retData = retData.filter((item) => {
                  return ( item.arvlMsg2.indexOf("[1") < 0 );  //메세지에 [1x] 번째는 제외
                });

                const timeTarget = document.querySelector("#searchtime_" + obj.line_num + "_" + obj.station_cd + "_" + obj.station_nm);
                if(timeTarget) {
                  timeTarget.innerHTML = "";
                }
                
                let tmpCnt = 1;
                let tmpUpdnLine = "";
                retData.map( (item) => {
                  if(tmpUpdnLine !== item.updnLine) {
                    tmpCnt = 1;
                  }
                  
                  if(timeTarget && tmpCnt === 1) {
                    if(item.recptnDt) {
                      const searchTime = String(item.recptnDt).substring(11, 19).replaceAll(":", "");
                      if((nowTime - searchTime) > 60) {
                        timeTarget.innerHTML = "<font color='red'><b>" + String(item.recptnDt).substring(11, 19) + "</b></font>";
                      }
                      else {
                        timeTarget.innerHTML = "<b>" + String(item.recptnDt).substring(11, 19) + "</b>";
                      }
                    }
                  }

                  if(tmpCnt <= 3) { //상행(3개) 하행(3개) 최대 6개만 노출
                    target.innerHTML += "[" + item.updnLine + "] " + item.trainLineNm + "<br />";
                    target.innerHTML += "　　▶ " + convertArrival(item.arvlMsg2, item.barvlDt) + " " + convertMinute(item.statnNm, item.arvlMsg2, item.barvlDt) + "<br />";
                  }
                  tmpUpdnLine = item.updnLine;
                  tmpCnt++;
                });
              }
            }
          }
        });
    });
  };

  //단일 건 조회 (재조회 시)
  const getSubwayListRefresh = (obj) => {
    const target = document.querySelector("#subway_" + obj.line_num + "_" + obj.station_cd + "_" + obj.station_nm);
    if(target) {
      target.innerHTML = "조회중";
    }

    const timeTarget = document.querySelector("#searchtime_" + obj.line_num + "_" + obj.station_cd + "_" + obj.station_nm);
    if(timeTarget) {
      timeTarget.innerHTML = "조회중";
    }

    const nowTime = moment(new Date()).format("HHmmss");
    axios
      .post("/api/subway/getArrivalList", {
        startIdx: 0,
        endIdx: 15,
        stationName: obj.station_nm,
      })
      .then((data) => {
        if (
          data &&
          data.data &&
          data.data.retData
        ) {

          if(target) {
            target.innerHTML = "";
            
            let retData = data.data.retData;
            if(retData && retData.length > 0) {
              retData = retData.filter((item) => {
                return ( item.subwayId === obj.subway_id ); //같은 노선만 추출
              });    
              retData = retData.filter((item) => {
                return ( item.arvlCd !== "1" );  //도착한 열차는 제외
              });
              retData = retData.filter((item) => {
                return ( item.arvlMsg2.indexOf("[1") < 0 );  //메세지에 [1x] 번째는 제외
              });

              let tmpCnt = 1;
              let tmpUpdnLine = "";
              retData.map( (item) => {
                if(tmpUpdnLine !== item.updnLine) {
                  tmpCnt = 1;
                }
                
                if(timeTarget && tmpCnt === 1) {
                  timeTarget.innerHTML = "";
                  if(item.recptnDt) {
                    const searchTime = String(item.recptnDt).substring(11, 19).replaceAll(":", "");
                    if((nowTime - searchTime) > 60) {
                      timeTarget.innerHTML = "<font color='red'><b>" + String(item.recptnDt).substring(11, 19) + "</b></font>";
                    }
                    else {
                      timeTarget.innerHTML = "<b>" + String(item.recptnDt).substring(11, 19) + "</b>";
                    }
                  }
                }

                if(tmpCnt <= 3) { //상행(3개) 하행(3개) 최대 6개만 노출
                  target.innerHTML += "[" + item.updnLine + "] " + item.trainLineNm + "<br />";
                  target.innerHTML += "　　▶ " + convertArrival(item.arvlMsg2, item.barvlDt) + " " + convertMinute(item.statnNm, item.arvlMsg2, item.barvlDt) + "<br />";
                }
                tmpUpdnLine = item.updnLine;
                tmpCnt++;
              });
            }
          }
        }
      });

  }

  const convertArrival = (arvlMsg, value) => {
    let sec = parseInt(value);
    let retValue = arvlMsg;

    if((sec !== 0 && sec < 600) || arvlMsg.indexOf("분 후") >= 0 || arvlMsg.indexOf("출발") >= 0 || arvlMsg.indexOf("도착") >= 0 || arvlMsg.indexOf("진입") >= 0 || arvlMsg.indexOf("[2]번째") >= 0 || arvlMsg.indexOf("[3]번째") >= 0) {
      retValue = "<span style='color:blue'>" + retValue + "</span>";
    }
    return retValue;
  }

  const convertMinute = (statnNm, arvlMsg, value) => {
    if(!value || value === undefined || value === "0") {
      return "";
    }

    if(arvlMsg.indexOf("분 후") >= 0) {
      return "";
    }

    if(arvlMsg.indexOf(statnNm + " 출발") >= 0) {
      return "";
    }

    let sec = parseInt(value);
    let retValue = "";
    if(sec >= 60) {
      retValue = parseInt(sec/60).toString() + "분";
      if((sec%60) !== 0) {
        retValue = retValue + " " + parseInt(sec%60).toString() + "초 후";
      }
      else {
        retValue = retValue + " 후";
      }
    }
    else {
      retValue = sec + "초 후";
    }

    if(arvlMsg === retValue) {
      return "";
    }

    if(sec < 600) {
      retValue = "<span style='color:blue'> [" + retValue + "]</span>";
    }
    else {
      retValue = " [" + retValue + "]";
    }
    return retValue;
  }

  const renderTip = () => {
    if (trainList && trainList.length > 0) {
      return (
        <Fragment>
          <p
            className="w3-left"
            style={{ marginTop: "5px", marginBottom: "0px", textAlign: 'left' }}
          >
            ※ (<i className="fas fa-redo link-cursor w3-large" />)를 <b>클릭</b> 시 해당역을 '재조회'합니다.<br/>
            ※ (<i className="far fa-heart link-cursor w3-large" />)를 <b>클릭</b> 시 '자주가는 역' 등록/해제<br/>
            ※ <b>'데이터 시간'과 '현재 시간'이 1분이상 차이</b>가 날 경우 <font color='red'><b>빨간색</b></font>으로 시간이 표시됩니다.<br/>
            ※ 도착 기준 <b>10분이내 이거나, [2] 또는 [3]번째 전역</b>에 위치할 경우 <font color='blue'><b>파랑색</b></font>으로 표시됩니다.<br/>            
            <b>※ 데이터 : 서울 열린데이터광장</b>
          </p>
        </Fragment>
      );
    }
  };

  const RenderTableTip = (obj) => {
    if(Helper.isEmpty(obj))
      return null;

    if(obj.idx === 0) {
      return (
        <div className="w3-right">
          <i className="fas fa-redo link-cursor w3-medium" /> 재조회 / <i className="fas fa-heart link-cursor w3-medium" /> 자주가는역
        </div>      
      )
    }
    else {
      return null;
    }
  }

  return (
    <Fragment>
      <div className="w3-center">
            {trainList &&
              trainList.map((item, idx) => {
                let favId =
                  String(item.line_num) +
                  "/" +
                  String(item.station_cd) +
                  "/" +
                  String(item.station_nm);

                let txtId =
                  String(item.line_num) +
                  "_" +
                  String(item.station_cd) +
                  "_" +
                  String(item.station_nm);

                return (
                  <>
                  {/* <RenderTableTip idx={idx} /> */}
                  <table className="w3-table-all w3-hoverable">
                    <thead>
                      <tr className="w3-green">
                        <th className="w3-center" style={{ width: "30%" }}>역명</th>     
                        <th className="w3-center" style={{ width: "30%" }}>노선</th>         
                        <th className="w3-center" style={{ width: "30%" }}>데이터 시간</th>
                        <th className="w3-center" style={{ width: "10%" }}>
                          <i
                            className={"fas fa-redo w3-medium link-cursor"} style={{color:"black"}}
                            onClick={() => getSubwayListRefresh(item)}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={"tr-" && idx}>
                        <td className="w3-center">
                          <b>{item.station_nm}</b>
                        </td>
                        <td className="w3-center">
                          <b>{item.line_num}</b>
                        </td>
                        <td className="w3-center">
                          <span id={"searchtime_" + txtId}></span>
                        </td>
                        <td className="w3-center">
                          <i
                            id={favId}
                            className={
                              SubwayCommon.getSubwayFavStyle(item) +
                              " w3-large link-cursor"
                            }
                            onClick={() => SubwayCommon.setSubwayFav(item)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="4">
                          <div id={"subway_" + txtId}></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br/>
                  </>
                );
              })}
            {trainList.length === 0 ? (
              <table className="w3-table-all w3-hoverable">
              <thead>
                <tr className="w3-green">
                  <th className="w3-center" style={{ width: "23%" }}>역명</th>     
                  <th className="w3-center" style={{ width: "22%" }}>노선</th>         
                  <th className="w3-center" style={{ width: "25%" }}>데이터 시간</th>
                  <th className="w3-center" style={{ width: "20%" }}>액션</th>
                </tr>
              </thead>
              <tbody>              
                <tr>
                  <td className="w3-center" colSpan={4}>
                    <NavLink to={"/" + props.pageId + "/search"}>
                      <span style={{ fontWeight: "bold" }}>
                        '역조회' 후 자주가는 역을 등록하세요.
                      </span>
                    </NavLink>
                  </td>
                </tr>
              </tbody>
            </table>
            ) : null}
        {trainList.length === 0 ? (
          <NavLink to={"/" + props.pageId + "/search"}>
            <img
              src={"/image/subway/guide.png"}
              className={"link-cursor"}
              style={{ maxWidth: "393px", width: "100%", paddingTop: "20px" }}
            />
          </NavLink>
        ) : renderTip()
        }
      </div>
    </Fragment>
  );
};

export default SubwayList;
