import React, { useEffect } from "react";

/* module */
import axios from "axios";

/* common */
import * as Helper from "../common/Helper.js";


const LoginForm = ({ history, location }) => {

  const loginSubmit = async (history) => {
    const loginId = document.querySelector("#LoginId");
  
    if (loginId && Helper.isEmpty(loginId.value)) {
      alert("'아이디'를 입력하세요.");
      loginId.focus();
      return;
    }
  
    const loginPwd = document.querySelector("#LoginPwd");
    if (loginPwd && Helper.isEmpty(loginPwd.value)) {
      alert("'비밀번호'를 입력하세요.");
      loginPwd.focus();
      return;
    }
  
    if (Helper.isEmpty(loginId) || Helper.isEmpty(loginPwd)) {
      alert("undefined error");
      return;
    }
  
    await axios.post("/api/login/login", {
      login_id: loginId.value,
      login_password: loginPwd.value,
    }).then((retData) => {
      if (!Helper.isEmpty(retData.data)) {
        const data = retData.data;
        if (data.statusCode === 200) {
          if (data.retData === "LoginOK" || data.retData === "NEED TO CHANGE PASSWORD") {
            Helper.loginPass(data, loginId.value, history);
          }
          else {
            console.log("login error", data.retMsg);
            window.sessionStorage.clear();
            window.localStorage.removeItem("localLoginID");
            alert(data.retMsg);
          }
        }
        else {
          console.log("login error", data.retMsg);
          window.sessionStorage.clear();
          window.localStorage.removeItem("localLoginID");
          alert(data.retMsg);
        }
      }
      else {
        console.log("login error", retData);
        window.sessionStorage.clear();
        window.localStorage.removeItem("localLoginID");
      }
    }).catch((e) => {
      console.log("login error", e);
      window.sessionStorage.clear();
      window.localStorage.removeItem("localLoginID");
      alert(e);
    });
  
  };


  return (
    <div className="w3-container w3-border">
      <form>
      <p>
        <label>
          <i className="fas fa-user-tie w3-xlarge"></i> 아이디
        </label>
      </p>
      <input
        type="text"
        id="LoginId"
        className="w3-input w3-border"
        placeholder="아이디"
        required="required"
        autoComplete="off"
      />
      <p>
        <label>
          <i className="fas fa-unlock-alt w3-xlarge"></i> 비밀번호
        </label>
      </p>
      <input
        className="w3-input w3-border"
        type="password"
        placeholder="비밀번호"
        id="LoginPwd"
        required="required"
        autoComplete="off"
      />
      <p>
        <button
          className="w3-button w3-block w3-green w3-left-align"
          onClick={(e) => {
            e.preventDefault(); 
            loginSubmit(history);
          }}
        >
          <i className="fa fa-search w3-margin-right"></i> Login
        </button>
      </p></form>
    </div>
  );
};

export default LoginForm;
