import { createAction, handleActions } from 'redux-actions';

export const SET_POPUP_CONTENT = 'popupData/SET_POPUP_CONTENT';
export const SET_INIT_POPUP = 'popupData/SET_INIT_POPUP';
export const SET_INIT_TOAST_POPUP = 'popupData/SET_INIT_TOAST_POPUP';

// 팝업 실행 함수
export const setPopupContent = createAction(SET_POPUP_CONTENT);

// 팝업 닫는 함수. 모든 상태가 초기화되고 Native popBackState() 가 실행된다.
export const setInitPopup = createAction(SET_INIT_POPUP);

export const setInitToastPopup = createAction(SET_INIT_TOAST_POPUP);

const initialState = {  
  popup: {
    open: false,
    quickBtn: false,
    bgLock: true,
    type: '',
    title: '',
    msg: '',
    confirmBtnMsg: '확인',
    cancelBtnMsg: '취소',
    confirmFunction: () => {},
    cancelFunction: () => {}
  }
};

export default handleActions(
  {
    
    [SET_POPUP_CONTENT]: (state, action) => {
      const newState = {
        ...state,
        popup: { ...action.payload, open: true }
      };
      return newState;
    },
    [SET_INIT_POPUP]: (state, action) => {
      
      const target = document.querySelector("#popup");
      if(target) {
        target.style = "display:none";
      }
      
      const newState = {
        ...state,
        popup: initialState.popup
      };
      return newState;
    },
    [SET_INIT_TOAST_POPUP]: (state, action) => {
      const newState = {
        ...state,
        popup: { ...action.payload, open: false }
      };
      return newState;
    },
  },
  initialState
);
