import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

/* common */
import * as Helper from "../common/Helper.js";

/* page */
import LoginForm from "./LoginForm";
import LoginUser from "./LoginUser";
import LoginFindId from "./LoginFindId";
import LoginFindPwd from "./LoginFindPwd";
import LoginPassword from "./LoginPassword.js";

function Login({ match, history, location }) {
  const pageId = Helper.getPageId(match.url);
  const page_type = match.params.page_type;

  useEffect( () => {
    if(Helper.isSession()) {
      //세션이 있는데 login 으로 접속 시 'user'로 변경 함
      if(pageId.indexOf("login") >= 0) {
        history.replace("/my/user");
      }
    }
    else {
      //세션이 없는데 user 으로 접속 시 'login'로 변경 함
      if(pageId.indexOf("my") >= 0) {
        history.replace("/login");
      }
    }
  }, [])

  const RenderComponent = (page_type) => {
    if(pageId === "my" && Helper.isEmpty(page_type)) {
      page_type = "user";
    }
    else if(pageId === "my" && Helper.isEmpty(page_type)) {
      page_type = "login";
    }

    switch (page_type) {

      case "find_id":
        return <LoginFindId history={history} location={location} />;

      case "find_pwd":
        return <LoginFindPwd history={history} location={location} />;

      case "password":
        return <LoginPassword history={history} location={location} />;

      case "user":
        return <LoginUser history={history} location={location} />;

      case "login":
      default:
        return <LoginForm history={history} location={location} />;
    }
  };

  const RenderLoginMenu = () => {
    if(Helper.isSession()) {
      return (
        <>
        <NavLink to={"/my/user"}>
          <button className="w3-bar-item w3-button">사용자정보</button>
        </NavLink>
        <NavLink to={"/my/password"}>
          <button className="w3-bar-item w3-button">비밀번호 변경</button>
        </NavLink>
        <NavLink to={"/logout"}>
          <button className="w3-bar-item w3-button">로그아웃</button>
        </NavLink>
        </>
      )
    }
    else {
      return (
        <>
        <NavLink to={"/login"}>
          <button className="w3-bar-item w3-button">로그인</button>
        </NavLink>
        <NavLink to={"/login/find_id"}>
          <button className="w3-bar-item w3-button">아이디 찾기</button>
        </NavLink>
        <NavLink to={"/login/find_pwd"}>
          <button className="w3-bar-item w3-button">비밀번호 찾기</button>
        </NavLink>
        </>
      )
    }
  }

  const LoginComponent = () => {
    return (
      <div className="w3-display-top" style={{ paddingBottom: "70px" }}>
        <div
          className="w3-bar w3-green"
          style={{ paddingBottom: "5px", paddingTop: "5px" }}
        >
          <RenderLoginMenu />
        </div>
        {<div className="w3-panel">{RenderComponent(page_type)}</div>}
      </div>
    );
  };

  return <LoginComponent />;
}
export default Login;
